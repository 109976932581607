import React from "react";
import newsroomFinalHeader from "../assets/images/newsroom-final-header.png";
import { Link, useNavigate } from "react-router-dom";

import "swiper/css/navigation";
import "swiper/css/pagination";

import div from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import newsDetailsHeader1 from "../assets/images/news-sample-header-1.png";
import newsDetailsHeader2 from "../assets/images/news-sample-header-2.png";
import NewsCard from "../components/NewsCard";

export default function ThirteenthPage() {
    const [news, setNews] = React.useState([]);

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_EXPRESS_BACKEND_URL}/news`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setNews(data);
            })
            .catch((error) => {
                console.error(
                    "There has been a problem with your fetch operation:",
                    error
                );
            });
    }, []);

    return (
        <div className="pb-20">
            <div className="relative pb-20">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820895/nutramed/newsroom-final-header_sojaxu.png"
                    alt="newsroom-header"
                    className="w-full"
                />
                <h1
                    className="absolute text-black font-montserrat font-bold
                    2xl:text-[42px] xl:text-[36px] lg:text-[32px] md:text-[28px] text-[24px]
                    2xl:top-[40%] 2xl:left-48 xl:top-[30%] xl:left-24 lg:top-[30%] lg:left-16 md:top-[25%] md:left-12 sm:top-[25%] sm:left-8 top-[25%] left-6"
                >
                    NEWSROOM
                </h1>
            </div>
            <div
                className="pt-12 flex justify-between
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="flex flex-col w-[70%]">
                    <div className="flex pb-8 items-center">
                        <h1 className="text-[24px] w-[15rem] font-montserrat font-bold">
                            LATEST NEWS
                        </h1>
                        <div className="border-b-2 border-black w-full"></div>
                    </div>
                    <div
                        className="grid gap-6
                        grid-cols-1 2xl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1"
                    >
                        {news.map((news) => (
                            <NewsCard key={news.id} news={news} />
                        ))}
                    </div>
                </div>
                <div className="w-[30%] pl-20 space-y-4">
                    <div className="flex items-center space-x-2">
                        <h1 className="text-[16px] font-montserrat text-start font-bold">
                            CATEGORIES
                        </h1>
                        <div className="border-b-2 border-black w-full"></div>
                    </div>
                    <div
                        animateOnce
                        animateIn="fadeInUp"
                        className="flex flex-col pt-4 space-y-4 justify-end"
                    >
                        <div className="border-b font-roboto text-[13px] pb-4 border-black ">
                            Latest news
                        </div>
                        <div className="border-b font-roboto text-[13px] pb-4 border-black ">
                            Corporate news
                        </div>
                        <div className="border-b font-roboto text-[13px] pb-4 border-black ">
                            Health tips
                        </div>
                        <div className="border-b font-roboto text-[13px] pb-4 border-black ">
                            Featured stories
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
