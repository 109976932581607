import React, { useState } from 'react';
import checkIcon from '../assets/icons/check.png';
import ProductMobileCard from '../components/ProductMobileCard';
import piracuf_1 from '../assets/images/piracuf-image-1.png';
import piracuf_2 from '../assets/images/piracuf-image-2.png';
import piracuf_3 from '../assets/images/piracuf-image-3.png';

export default function ProductDetailsMobile({ productId }) {
    const products = {
        product_id: "P003",
        product_name: "Piracuf Syrup",
        product_details: "Piracuf Syrup is an herbal supplement for cough relief, and soothing sore throat. Formulated with a blend of 12 traditional herbs, Piracuf Syrup provides natural relief for respiratory discomfort in the throat.",
        product_description: 'Piracuf Syrup: A herbal blend for cough relief and soothing sore throats, formulated with 12 traditional herbs for natural respiratory comfort.',
        benefits: [
            "Soothes sore throat.",
            "Supports healthy respiratory function.",
        ],
        contraindications: [
            "Hypersensitivity or allergy to Piracuf Syrup’s components.",
            "Pregnancy.",
            "Individuals with diarrhea, spleen or stomach disease, or yin deficiency.",
        ],
        dosage_and_administration: [
            "Children 1-6 years old: 5-10 ml, 2-3 times per day.",
            "Children 6-12 years old: 10 ml, 2-3 times per day.",
            "Children over 12 years old and adults: 15 ml, 2-3 times per day.",
        ],
        disclaimer: [
            "Consult a healthcare professional before use.",
            "This product is a dietary supplement and is not intended to be a substitute for medication.",
            "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
        ],
        storage: "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
        packaging: "Box of 1 bottle x 120ml",
        ingredients: [
            { ingredients_name: "Clerodenron cytophyllum Turcz", ingredients_amount: "750mg" },
            { ingredients_name: "Rhizoma Belamcandae chinensis", ingredients_amount: "750mg" },
            { ingredients_name: "Platycodon grandiflorum", ingredients_amount: "750mg" },
            { ingredients_name: "Fructus Trichosanthis", ingredients_amount: "750mg" },
            { ingredients_name: "Fructus Gardeniae", ingredients_amount: "584mg" },
            { ingredients_name: "Radix Peucedani", ingredients_amount: "584mg" },
            { ingredients_name: "Radix Scutellariae", ingredients_amount: "584mg" },
            { ingredients_name: "Radix Stemonae tuberosae", ingredients_amount: "584mg" },
            { ingredients_name: "Asparagus cochinchinensis", ingredients_amount: "584mg" },
            { ingredients_name: "Pericarpium Citri Reticulatae perene", ingredients_amount: "500mg" },
            { ingredients_name: "Cortex Mori Albae Radicis", ingredients_amount: "500mg" },
            { ingredients_name: "Bulbus Fritillariae cirrlosae", ingredients_amount: "500mg" }
        ],
        images: [
            piracuf_1,
            piracuf_2,
            piracuf_3
        ]
    };

    const [selectedImage, setSelectedImage] = useState(products.images[0]);

    return (
        <div className='bg-white space-y-8 py-8 rounded-md'>
            <h1 className='text-[24px] px-8 text-[#2E6D70] font-bold text-start'>Category's Name</h1>
            <div className='flex flex-col shadow-xl px-12 py-8'>
                <img
                    src={selectedImage}
                    alt={products.product_name}
                    className=''
                />
                <div className=''>
                    <div className='flex flex-col space-y-8 items-center justify-center'>
                        <div className='flex mt-4 space-x-4'>
                            {products.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`piracuf-${index}`}
                                    className={`w-16 h-16 cursor-pointer ${selectedImage === image ? 'bg-gray-200' : ''}`}
                                    onClick={() => setSelectedImage(image)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className='flex pb-8 space-y-4 flex-col'>
                    <div className='flex pt-4 justify-center items-center'>
                        <div className='space-y-4'>
                            <h1 className='text-[42px] font-montserrat font-bold text-black'>{products.product_name}</h1>
                            <p className='text-[13px] text-justify font-roboto text-black'>{products.product_details}</p>
                            <div className='space-y-2'>
                                <ul className='space-y-2'>
                                    {products.benefits.map((benefit, index) => (
                                        <li key={index} className='text-[16px] font-bold font-roboto items-center text-black'>
                                            <img src={checkIcon} alt='check' className='w-6 h-6 inline-block mr-2' />
                                            {benefit}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className="space-y-2">
                            <h2 className="text-[16px] font-roboto font-bold text-black">Ingredients</h2>
                            <table className="w-full font-roboto border border-gray-300">
                                <tbody>
                                    {products.ingredients.map((ingredient, index) => (
                                        <tr key={index} className="border-b border-gray-300">
                                            <td className="py-2 px-4 text-[13px] text-gray-700">{ingredient.ingredients_name}</td>
                                            <td className="py-2 px-4 text-[13px] text-gray-700">{ingredient.ingredients_amount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className='space-y-2 pb-4'>
                    <h2 className='text-[16px] font-roboto font-bold text-black'>Contraindications</h2>
                    <ul className=''>
                        {products.contraindications.map((contraindication, index) => (
                            <li key={index} className='text-[13px] text-gray-500'>
                                {contraindication}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='space-y-2 pb-4'>
                    <h2 className='text-[16px] font-roboto font-bold text-black'>Dosage and Administration</h2>
                    <ul className=''>
                        {products.dosage_and_administration.map((dosage, index) => (
                            <li key={index} className='text-[13px] text-gray-500'>
                                {dosage}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='space-y-2 pb-4'>
                    <h2 className='text-[16px] font-roboto font-bold text-black'>Disclaimer</h2>
                    <ul className=''>
                        {products.disclaimer.map((disclaimer, index) => (
                            <li key={index} className='text-[13px] text-gray-500'>
                                {disclaimer}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='space-y-2 pb-4'>
                    <h2 className='text-[16px] font-roboto font-bold text-black'>Storage</h2>
                    <p className='text-[13px] text-gray-500'>{products.storage}</p>
                </div>
                <div className='space-y-2 pb-4'>
                    <h2 className='text-[16px] font-roboto font-bold text-black'>Packaging</h2>
                    <p className='text-[13px] text-gray-500'>{products.packaging}</p>
                </div>
            </div>
            <div className='space-y-8 px-12'>
                <h1 className='text-[24px] text-[#2E6D70] font-bold text-start'>Similar Products</h1>
                <ProductMobileCard product={products} />
                <ProductMobileCard product={products} />
            </div>
        </div>
    )
}
