import React from "react";
import nutramedLogo from "../assets/images/nutramed_logo_01.png";
import moreIcon from "../assets/icons/more.png";
import { Link, useNavigate } from "react-router-dom";

export default function NavBarMobile() {
    const [showDropdown, setShowDropdown] = React.useState(false);

    const navigate = useNavigate();
    const navRef = React.useRef(null);

    const handleShowDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCloseDropdown = (page) => {
        if (page) {
            navigate(page);
            window.scrollTo(0, 0);
        }

        setShowDropdown(false);
    };

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <nav className="flex flex-row justify-between px-4 sticky top-0 z-50 bg-black bg-opacity-30 backdrop-blur-sm text-white">
            <div onClick={() => handleCloseDropdown("/")}>
                <img
                    src={nutramedLogo}
                    className="w-[96px] h-[80px]"
                    alt="Nutramed Corporation Logo"
                />
            </div>
            <div>
                <img
                    src={moreIcon}
                    className="w-8 h-8 my-6"
                    onClick={handleShowDropdown}
                    alt="more"
                />
                {showDropdown && (
                    <div
                        ref={navRef}
                        className="absolute animate__animated animate__slideInRight font-semibold right-0 w-full flex flex-col bg-white text-[#2E6D70] shadow-lg border-t-2 border-[#2E6D70]"
                    >
                        <div
                            onClick={() => handleCloseDropdown("/about-us")}
                            className="text-[13px] px-8 py-4 cursor-pointer hover:text-white hover:bg-[#2E6D70] hover:underline transform transition-colors duration-200"
                        >
                            ABOUT
                        </div>
                        <div
                            onClick={() => handleCloseDropdown("/solutions")}
                            className="text-[13px] px-8 py-4 cursor-pointer hover:text-white hover:bg-[#2E6D70] hover:underline transform transition-colors duration-200"
                        >
                            SOLUTIONS
                        </div>
                        <div
                            onClick={() => handleCloseDropdown("/products")}
                            className="text-[13px] px-8 py-4 cursor-pointer hover:text-white hover:bg-[#2E6D70] hover:underline transform transition-colors duration-200"
                        >
                            PRODUCTS
                        </div>
                        <div
                            onClick={() => handleCloseDropdown("/csr")}
                            className="text-[13px] px-8 py-4 cursor-pointer hover:text-white hover:bg-[#2E6D70] hover:underline transform transition-colors duration-200"
                        >
                            CSR
                        </div>
                        <div
                            onClick={() => handleCloseDropdown("/newsroom")}
                            className="text-[13px] px-8 py-4 cursor-pointer hover:text-white hover:bg-[#2E6D70] hover:underline transform transition-colors duration-200"
                        >
                            NEWSROOM
                        </div>
                        <div
                            onClick={() => handleCloseDropdown("/contact-us")}
                            className="text-[13px] px-8 py-4 cursor-pointer hover:text-white hover:bg-[#2E6D70] hover:underline transform transition-colors duration-200"
                        >
                            CONTACT US
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
}
