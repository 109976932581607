import React from "react";
import { marked } from "marked";
import "react-quill/dist/quill.snow.css";
import "../App.css";

// CREATE TABLE news (
//     id INT AUTO_INCREMENT PRIMARY KEY,
//     title VARCHAR(255),
//     content TEXT,
//     author VARCHAR(100),
//     published_date DATE,
//     tags JSON,
//     summary TEXT,
//     source_url VARCHAR(255),
//     thumbnail_url VARCHAR(255)
// );


export default function NewsDetails() {
    const [news, setNews] = React.useState({});

    const id = window.location.pathname.split("/")[2];

    React.useEffect(() => {
        const fetchNews = async () => {
            console.log(process.env.REACT_APP_EXPRESS_BACKEND_URL);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_EXPRESS_BACKEND_URL}/news/${id}`
                );
                const data = await response.json();
                setNews(data);
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchNews();
    }, []);

    const formatDate = (date) => {
        const d = new Date(date);
        const formattedDate = `${d.getDate()}/${
            d.getMonth() + 1
        }/${d.getFullYear()}`;
        return formattedDate;
    };

    return (
        <div className="px-48 py-12 space-y-4">
            <h1 className="text-4xl font-bold font-montserrat">{news.title}</h1>
            <div className="flex flex-row justify-between">
                <p className="text-sm font-roboto text-gray-500">By {news.author}</p>
                <p className="text-sm font-roboto text-gray-500">
                    Published on {formatDate(news.published_date)}
                </p>
            </div>
            <div
                className="py-4 font-montserrat ck-content"
                dangerouslySetInnerHTML={{ __html: news.content }}
            ></div>
        </div>
    );
}
