import React from "react";
import acc_1 from "../assets/images/acc-1.png";
import acc_2 from "../assets/images/acc-2.png";
import acc_3 from "../assets/images/acc-3.png";
import lineCurve from "../assets/images/line-curve.png";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
} from "@chakra-ui/react";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function SeventhPage() {
    const [activeIndex, setActiveIndex] = React.useState(0);

    const items = [
        {
            title: "Our Mission",
            content:
                "Guarantee transparency, honesty, and ethical conduct in all aspects of our operations. Continuously uphold rigorous standards to drive innovation and deliver trusted healthcare solutions to people around the world. Be a leader in environmental stewardship and sustainable practices throughout our product lifecycle.",
            imgSrc: acc_1,
        },
        {
            title: "Our Vision",
            content:
                "Research and develop more high-quality products, increase production capacity, and expand our global footprint to bring health to the whole world.",
            imgSrc: acc_2,
        },
        {
            title: "Our Philosophy",
            content:
                "With unwavering dedication and strategic investments, Nutramed is poised to ascend to the forefront of Vietnam's pharmaceutical industry.",
            imgSrc: acc_3,
        },
    ];

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <div
            animateOnce
            animateIn="fadeInLeft"
            className="space-y-16 h-auto flex flex-col items-center px-12"
        >
            <img src={lineCurve} alt="line-curve" className="w-full" />
            <div
                className="flex py-12 flex-col md:flex-row items-center space-x-8 justify-between
      2xl:px-36 xl:px-24 lg:px-16 md:px-8 sm:px-4 px-2"
            >
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    className="w-full md:w-1/2 space-y-4"
                >
                    <Accordion defaultIndex={[0]} className="">
                        <AccordionItem>
                            <h2>
                                <AccordionButton
                                    _hover={{ bg: "gray.200" }}
                                    onClick={() => setActiveIndex(0)}
                                >
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Our Mission
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 space-y-2 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={14}
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Guarantee transparency, honesty, and
                                            ethical conduct in all aspects of
                                            our operations.
                                        </Text>
                                        <Text
                                            fontSize={14}
                                            fontFamily="Roboto"
                                            mt={1}
                                        >
                                            Uphold rigorous standards to drive
                                            innovation and deliver trusted
                                            healthcare solutions to people
                                            around the world.
                                        </Text>
                                        <Text
                                            fontSize={14}
                                            fontFamily="Roboto"
                                            mt={1}
                                        >
                                            Be a leader in environmental
                                            stewardship and sustainable
                                            practices throughout our product
                                            lifecycle.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton
                                    _hover={{ bg: "gray.200" }}
                                    onClick={() => setActiveIndex(1)}
                                >
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Our Vision
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={14}
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Research and develop more
                                            high-quality products, increase
                                            production capacity, and expand our
                                            global footprint to bring health to
                                            the whole world.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton
                                    _hover={{ bg: "gray.200" }}
                                    onClick={() => setActiveIndex(2)}
                                >
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Our Philosophy
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={14}
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            With unwavering dedication and
                                            strategic investments, Nutramed is
                                            poised to ascend to the forefront of
                                            Vietnam's pharmaceutical industry.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </ScrollAnimation>
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`w-full md:w-1/2 ${
                            activeIndex === index ? "block" : "hidden"
                        }`}
                    >
                        <img
                            src={item.imgSrc}
                            alt={item.title}
                            className="w-full"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
