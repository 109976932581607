import React from "react";
import solutionsHeader2 from "../assets/images/solutions-header-2.png";
import lineCurve from "../assets/images/line-curve.png";
import solution1 from "../assets/images/solution1.png";
import solution2 from "../assets/images/solution2.png";
import solution3 from "../assets/images/solution3.png";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
} from "@chakra-ui/react";
import solutionSide1 from "../assets/images/solutions-side-1.png";
import solutionSide2 from "../assets/images/solutions-side-2.png";
import { Link, useNavigate } from "react-router-dom";
import solutionsMobile1 from "../assets/images/solutions-mobile-1.png";
import solutionsMobile2 from "../assets/images/solutions-mobile-2.png";

export default function TenthMobile() {
    const [activeSection, setActiveSection] = React.useState("planning");

    const handleClick = (index) => {
        setActiveSection(index);
    };

    const navigate = useNavigate();

    return (
        <div>
            <div className="relative bg-white font-montserrat">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820924/nutramed/solutions-mobile-1_aemiaf.png"
                    alt="background"
                    className=""
                />
                <div className="absolute items-start text-white space-y-2 flex flex-col top-1/2 transform -translate-y-1/2 left-6">
                    <h1
                        className=" text-white text-4xl font-montserrat font-bold"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    >
                        SOLUTIONS
                    </h1>
                </div>
            </div>
            <div className="text-center w-full flex self-center flex-col items-center font-roboto justify-center">
                <div className="flex px-8 space-y-2 my-4 flex-col">
                    <h1 className="text-2xl font-montserrat font-bold text-center mt-4">
                        NUTRAMED CORPORATION
                    </h1>
                    <h2 className="text-lg font-montserrat font-semibold text-center">
                        BRINGING SCIENCE TO LIFE
                    </h2>
                    <p className="text-sm text-gray-500 font-roboto font-normal text-center">
                        Nutramed continually strives to maintain an unwavering
                        reputation for the delivery of exceptional products,
                        services, and solutions where quality and dependability
                        are of the greatest importance.
                    </p>
                </div>
            </div>
            <div className="px-8 mb-4">
                <img src={lineCurve} alt="line curve" className="w-full" />
            </div>
            <div className="shadow-2xl bg-white">
                <div className="flex space-x-12 px-4 select-none pt-8 h-[36rem] items-center">
                    <div className="space-y-2 flex flex-col justify-between">
                        <p className="text-[13px] text-gray-500 font-roboto font-normal">
                            Pharmaceutical and nutraceutical
                        </p>
                        <div className="flex items-center justify-between">
                            <h1 className="text-[24px] text-gray-800 font-montserrat font-bold">
                                FACTORIES
                            </h1>
                        </div>
                        <img src={solution1} alt="solution-1" className="" />
                        <div className="space-y-2 pb-4">
                            <p className="text-[13px] text-black font-roboto font-normal">
                                Located in Vung Tau and Long An Province, our
                                GMP and USFDA certified factories span a total
                                area of 5,000m².
                            </p>
                        </div>
                        <div className="space-y-4">
                            <p className="text-[13px] text-justify text-gray-500 font-roboto font-normal">
                                Equipped with advanced facilities including
                                storage warehouses, specialized preparation
                                areas, and enclosed extraction units with
                                cutting-edge automated production lines, we
                                combine innovation and efficiency to deliver
                                high-quality products swiftly and reliably.{" "}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col pb-12 justify-between">
                    <Accordion className="" allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Dosage forms portfolio
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            Tablets, capsules, powders,
                                            solutions, and more.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Ingredients
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            Our ingredients are thoroughly
                                            selected, purchased, and rigorously
                                            tested by a testing facility
                                            compliant with the Ministry of
                                            Health's GLP guidelines, ensuring
                                            that the ingredients meet strict
                                            quality criteria, including clear
                                            origin and compliance with the
                                            Vietnamese Pharmacopoeia standards.
                                        </Text>
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={2}
                                        >
                                            We also apply GACP, GCP, and GSP
                                            standards for importation,
                                            transportation, storage and
                                            preservation processes to control
                                            our imported medicinal materials.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Nutramed Commitment
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            We prioritize transparency, honesty,
                                            and ethical conduct in all aspects
                                            of operations.
                                        </Text>
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={2}
                                        >
                                            Upholding rigorous standards to
                                            drive innovation and deliver trusted
                                            healthcare solutions to every
                                            client, we are dedicated to
                                            environmental stewardship and
                                            sustainable practices throughout our
                                            product lifecycle.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>

            <div className="shadow-2xl mt-8 bg-white">
                <div className="flex space-x-12 px-4 select-none py-8 h-[36rem] items-center">
                    <div className="space-y-2 flex flex-col justify-between">
                        <div className="flex items-center justify-end">
                            <h1 className="text-[24px] text-gray-800 font-montserrat font-bold">
                                LABORATORIES
                            </h1>
                        </div>
                        <img src={solution2} alt="solution-2" className="" />
                        <div className="space-y-2 pb-4">
                            <p className="text-[13px] text-black font-roboto font-normal">
                                Location: Canada
                            </p>
                            <p className="text-[13px] text-black font-roboto font-normal">
                                Certifications: GMPWHO
                            </p>
                            <p className="text-[13px] text-black font-roboto font-normal">
                                Facilities:
                                <span className="flex flex-col ml-4 space-y-1 mt-1">
                                    <span className="flex space-x-2">
                                        <span className="text-black">
                                            Analytical Lab
                                        </span>
                                    </span>
                                    <span className="flex space-x-2">
                                        <span className="text-black">
                                            Microbiological Lab
                                        </span>
                                    </span>
                                    <span className="flex space-x-2">
                                        <span className="text-black">
                                            Research and Development (R&D Lab)
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div className="space-y-4">
                            <p className="text-[13px] text-justify text-gray-500 font-roboto font-normal">
                                Nutramed's laboratories are equipped with the
                                latest technology and equipment to support our
                                mission of delivering innovative and
                                high-quality healthcare products.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex pb-12 justify-between">
                    <Accordion className="w-full" allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Analytical Lab
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            Analytical lab provides accurate and
                                            rapid analytical testing services
                                            for vitamins, nutraceuticals, and
                                            nutritional supplements. It is a
                                            dust contained flexible facility
                                            designed for cost effective and
                                            efficient processing of all tests
                                            performed, ranging from premixes and
                                            supplements through to fortified and
                                            natural finished products.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Microbiological Lab
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            We conduct comprehensive
                                            microbiological testing at all
                                            production stages, including
                                            environmental monitoring,
                                            problem-solving and quality
                                            assurance. Our lab adheres to
                                            rigorous USDA and FDA regulations,
                                            employing validated methods to
                                            detect harmful microorganisms and
                                            pathogens.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Research and Development (R&D Lab)
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 py-2 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            Our team of experienced PhD
                                            scientists provides clients with
                                            cutting-edge formulations and
                                            ensures product consistency across
                                            various delivery methods, including
                                            liquids, capsules, tablets, and
                                            powders.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="shadow-2xl mt-8 bg-white">
                <div className="flex space-x-12 px-4 select-none py-8 h-[32rem] items-center">
                    <div className="space-y-2 flex flex-col justify-between">
                        <div className="flex items-center justify-between">
                            <h1 className="text-[24px] text-gray-800 font-montserrat font-bold">
                                R&D CENTER
                            </h1>
                        </div>
                        <img src={solution3} alt="solution-3" className="" />
                        <div className="space-y-2 pb-4">
                            <p className="text-[13px] text-black font-roboto font-normal">
                                Location: Vancouver, Canada
                            </p>
                        </div>
                        <div className="space-y-4">
                            <p className="text-[13px] text-justify text-gray-500 font-roboto font-normal">
                                Nutramed's Canadian laboratories, adhering to
                                GMP-WHO standards, are at the forefront of
                                healthcare innovation. Our advanced analytical,
                                microbiological, and research and development
                                capabilities facilitate the creation and
                                rigorous evaluation of Nutramed's superior
                                products.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex pb-12 justify-between">
                    <Accordion className="w-full" allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Human Resource
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            Hub of interdisciplinary expertise,
                                            housing specialists in medicine,
                                            pharmacology, physical chemistry,
                                            biochemistry, immunology, biology,
                                            and beyond.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton _hover={{ bg: "gray.200" }}>
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize={16}
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Nutramed Commitment
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-justify space-x-4 justify-between">
                                    <Box className="flex-1 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize={13}
                                            fontFamily="Roboto"
                                            mt={0}
                                        >
                                            Our team of PhD scientists, who have
                                            more than 100 years of combined
                                            experience in pharmaceutical and
                                            nutraceutical product development,
                                            provides leading scientific
                                            knowledge to pioneer new product
                                            development while maintaining the
                                            highest quality and consistency.
                                        </Text>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="relative mt-12">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820921/nutramed/solutions-header-2_hqxmcg.png"
                    className="w-full"
                    alt="Solutions Header 2"
                />
                <div className="absolute top-1/2 transform -translate-y-1/2 left-4">
                    <h1 className=" text-white text-[13px] font-montserrat font-bold">
                        WE PROVIDE FULL-SERVICES
                    </h1>
                    <h2 className=" text-white text-2xl font-montserrat font-bold">
                        SOLUTION
                    </h2>
                </div>
            </div>
            <div className="flex h-[3rem] font-montserrat justify-between w-full">
                <div
                    className={`flex-1 hover:cursor-pointer flex font-semibold items-center justify-center text-[13px] transition-colors duration-300 ${
                        activeSection === "planning"
                            ? "bg-gray-800 text-white"
                            : "bg-gray-300 text-black"
                    }`}
                    onClick={() => handleClick("planning")}
                >
                    PLANNING
                </div>
                <div
                    className={`flex-1 hover:cursor-pointer flex font-semibold items-center justify-center text-[13px] transition-colors duration-300 ${
                        activeSection === "formulation"
                            ? "bg-gray-800 text-white"
                            : "bg-gray-300 text-black"
                    }`}
                    onClick={() => handleClick("formulation")}
                >
                    FORMULATION
                </div>
                <div
                    className={`flex-1 hover:cursor-pointer flex font-semibold items-center justify-center text-[13px] transition-colors duration-300 ${
                        activeSection === "labeling"
                            ? "bg-gray-800 text-white"
                            : "bg-gray-300 text-black"
                    }`}
                    onClick={() => handleClick("labeling")}
                >
                    LABELING
                </div>
            </div>
            {activeSection === "planning" && (
                <div className="flex pt-4 px-4 flex-col">
                    <div className="space-y-2">
                        <p className="text-sm text-gray-500 font-roboto font-normal">
                            Nutramed offers market research and product
                            development for health supplements.
                        </p>

                        <p className="text-sm text-gray-500 font-roboto font-normal">
                            By understanding our clients and their goals, we
                            help them identify your target market and develop a
                            product launch plan to achieve end-consumer product
                            viability and maximize profit potential.
                        </p>
                    </div>
                </div>
            )}
            {activeSection === "formulation" && (
                <div className="flex pt-4 flex-col">
                    <div className="space-y-4 px-4 mb-4">
                        <p className="text-sm text-gray-500 font-roboto font-normal">
                            We assist clients in identifying the proper product
                            ingredients, and the optimal delivery medium.
                        </p>
                        <p className="text-sm text-gray-500 font-roboto font-normal">
                            Chemists, research scientists, pharmacists, and
                            product formulation managers at Nutramed apply cGMP
                            compliance methodologies to ensure the highest
                            quality and effectiveness for the consumers.
                        </p>
                    </div>
                    <div className="mt-4 space-y-4">
                        <div className="w-full">
                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton
                                            _hover={{ bg: "gray.200" }}
                                        >
                                            <Box
                                                flex="1"
                                                textAlign="left"
                                                fontSize={16}
                                                fontWeight="bold"
                                                fontFamily="Roboto"
                                            >
                                                FORMULATION AND PROCESS
                                                DEVELOPMENT
                                            </Box>
                                            <AccordionIcon boxSize={6} />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Box className="flex space-x-4 py-8 justify-between">
                                            <Box className="flex-1 text-gray-500 flex flex-col">
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={-4}
                                                >
                                                    Blending, drying,
                                                    granulation, and coating
                                                    development
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Brand extension formulation
                                                    development
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Dosage form specification
                                                    development
                                                </Text>
                                            </Box>
                                            <Box className="flex-1 text-gray-500 flex flex-col">
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={-4}
                                                >
                                                    Excipient compatibility
                                                    studies
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Excipient selection and
                                                    ration determination
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Formulation of drug
                                                    products: solids and
                                                    semi-solids
                                                </Text>
                                            </Box>
                                            <Box className="flex-1 text-gray-500 flex flex-col">
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={-4}
                                                >
                                                    Immediate release dosage
                                                    form development
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Manufacturing process
                                                    optimization
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Modified and sustained
                                                    release dosage form
                                                    development
                                                </Text>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton
                                            _hover={{ bg: "gray.200" }}
                                        >
                                            <Box
                                                flex="1"
                                                textAlign="left"
                                                fontSize={16}
                                                fontWeight="bold"
                                                fontFamily="Roboto"
                                            >
                                                FORMULATION EVALUATION
                                            </Box>
                                            <AccordionIcon boxSize={6} />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Box className="flex space-x-4 py-8 justify-between">
                                            <Box className="flex-1 text-gray-500 flex flex-col">
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={-4}
                                                >
                                                    Compressibility evaluation
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Content uniformity
                                                    evaluation
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Disintegration testing
                                                </Text>
                                            </Box>
                                            <Box className="flex-1 text-gray-500 flex flex-col">
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={-4}
                                                >
                                                    Dissolution testing
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Friability testing
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    In-vitro bioequivalence
                                                    determination
                                                </Text>
                                            </Box>
                                            <Box className="flex-1 text-gray-500 flex flex-col">
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={-4}
                                                >
                                                    Powder flow determination
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Stability assessment
                                                </Text>
                                                <Text
                                                    fontSize="sm"
                                                    fontFamily="Roboto"
                                                    mt={4}
                                                >
                                                    Tap-density testing
                                                </Text>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            )}
            {activeSection === "labeling" && (
                <div className="flex pt-4 px-4 flex-col">
                    <div className="space-y-2">
                        <p className="text-sm text-gray-500 font-roboto font-normal">
                            Nutramed provides label design, label printing, and
                            packaging services.
                        </p>
                        <p className="text-sm text-gray-500 font-roboto font-normal">
                            We offer a variety of design packaging that can
                            specifically meet your needs.
                        </p>
                        <p className="text-sm text-gray-500 font-roboto font-normal">
                            Nutramed has an experienced team of ID and brand
                            product managers who are available to assist you in
                            developing packages, label designs, and overall
                            brand affinity for your products, which in turn
                            helps you bring a complete package to the end
                            consumer.
                        </p>
                    </div>
                </div>
            )}
            <div className="text-center flex flex-col justify-center px-8 py-8 space-y-4">
                <p className="text-[14px] italic text-gray-800 font-roboto font-normal">
                    We take care of all aspects of the final product, from
                    ensuring labeling and design meet FDA specifications, to
                    safety seals, neck bands, child restraints, and all other
                    aspects of the final product being in place as needed. If
                    you are interested in learning more about Nutramed private
                    label contract manufacturing services and solutions, please
                    contact us today.
                </p>
            </div>
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820923/nutramed/solutions-mobile-2_pfdhfn.png"
                    alt="solutionSide1"
                    className="w-full"
                />
                <div className="absolute top-4 w-full items-center text-center">
                    <h1 className=" text-white text-base font-montserrat font-bold">
                        Nutramed Corporation commits to
                    </h1>
                    <h2 className=" text-white text-2xl font-montserrat font-bold">
                        HIGH-QUALITY PRODUCTS
                    </h2>
                </div>
                <button
                    onClick={() => {
                        navigate("/products");
                        window.scrollTo(0, 0);
                    }}
                    className="border-2 left-1/2 -translate-x-1/2 absolute top-[20%] w-[20rem] text-center border-white hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-semibold text-lg text-white px-8 py-2 rounded-md transform transition-colors duration-200"
                >
                    VIEW ALL PRODUCTS
                </button>
            </div>
            <div className="flex flex-col justify-between h-full bg-gray-200 pb-8 px-8">
                <div className="flex flex-col justify-center space-y-8 py-8">
                    <div className="space-y-1">
                        <h1 className="text-[16px] font-montserrat font-bold">
                            Suplliers
                        </h1>
                        <div className="border-b-2 pr-12 border-b-white"></div>
                        <p className="text-[13px] text-gray-500 font-roboto font-normal">
                            Putting customers at heart of everything we do,
                            Nutramed offers comprehensive solutions to elevate
                            your growing business.
                        </p>
                    </div>
                    <div className="space-y-1">
                        <h1 className="text-[16px] font-montserrat font-bold">
                            Distributors
                        </h1>
                        <div className="border-b-2 pr-12 border-b-white"></div>
                        <p className="text-[13px] text-gray-500 font-roboto font-normal">
                            We are seeking strategic distribution partners to
                            expand our global footprint and deliver superior
                            healthcare solutions to markets around the world.
                        </p>
                    </div>
                </div>
                <div
                    onClick={() => {
                        navigate("/contact-us");
                        window.scrollTo(0, 0);
                    }}
                    className="w-full"
                >
                    <img
                        src={solutionSide2}
                        alt="solution-side-1"
                        className="rounded-2xl"
                    />
                </div>
            </div>
        </div>
    );
}
