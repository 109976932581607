import React from "react";
import formBG from "../assets/images/form-bg.png";
import phoneIcon from "../assets/icons/phone.png";
import emailIcon from "../assets/icons/email.png";
import locationIcon from "../assets/icons/location.png";
import facebookIcon from "../assets/icons/facebook.png";
import linkedinIcon from "../assets/icons/linkedin.png";
import globeIcon from "../assets/icons/globe.png";
import contactMobile1 from "../assets/images/contact-mobile-1.png";

export default function NinthMobile() {
    const [formData, setFormData] = React.useState({
        sender_name: "",
        sender_address: "",
        sender_email: "",
        sender_company: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_EXPRESS_BACKEND_URL}/mails`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (response.ok) {
                alert("Message sent successfully!");
                setFormData({
                    sender_name: "",
                    sender_address: "",
                    sender_email: "",
                    sender_company: "",
                    message: "",
                });
            } else {
                alert("Failed to send message. Please try again.");
            }
        } catch (error) {
            console.error("Error sending message: ", error);
        }
    };

    // const [googleMap, setGoogleMap] = React.useState(
    //     "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.9349928274605!2d106.7047367!3d10.739493699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f994eb03551%3A0x32cef81515f791c3!2zNTc3IMSQLiBOZ3V54buFbiBUaOG7iyBUaOG6rXAsIEtodSDEkcO0IHRo4buLIEhpbSBMYW0sIFF14bqtbiA3LCBI4buTIENow60gTWluaCA3MjkxNQ!5e0!3m2!1svi!2s!4v1721638981097!5m2!1svi!2s"
    // );

    // const handleLocationClicked = (location) => {
    //     switch (location) {
    //         case "head-office":
    //             setGoogleMap(
    //                 "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.9349928274605!2d106.7047367!3d10.739493699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f994eb03551%3A0x32cef81515f791c3!2zNTc3IMSQLiBOZ3V54buFbiBUaOG7iyBUaOG6rXAsIEtodSDEkcO0IHRo4buLIEhpbSBMYW0sIFF14bqtbiA3LCBI4buTIENow60gTWluaCA3MjkxNQ!5e0!3m2!1svi!2s!4v1721638981097!5m2!1svi!2s"
    //             );
    //             break;
    //         case "factory-1":
    //             setGoogleMap(
    //                 "https://www.google.com/maps/embed/v1/place?key=AIzaSyCVgO8KzHQ8iKcfqXgrMnUIGlD-piWiPpo&q=Lo+B31-1,Duong+Doc+2,Khu+cong+nghiep+Phu+An+Thanh,Xa+An+Thanh,Huyen+Ben+Luc,Tinh+Long+An,Viet+Nam&zoom=15&language=vi"
    //             );
    //             break;
    //         case "factory-2":
    //             setGoogleMap(
    //                 "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.8514593869363!2d107.29985887457194!3d10.512363464009876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175a0055508d3a3%3A0x4642f80a0c9eaf1a!2zS2h1IGPDtG5nIG5naGnhu4dwIMSQ4bqldCDEkOG7jyAx!5e0!3m2!1svi!2s!4v1721640754644!5m2!1svi!2s"
    //             );
    //             break;
    //         default:
    //             break;
    //     }
    // };

    return (
        <div className="pb-8">
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820731/nutramed/contact-mobile-1_aoe85d.png"
                    className="w-full"
                    alt="Contact Us Header"
                />
                <div className="absolute items-start text-white space-y-2 flex flex-col top-1/2 transform -translate-y-1/2 left-6">
                    <h1
                        className=" text-white text-4xl font-montserrat font-bold"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    >
                        CONTACT US
                    </h1>
                </div>
            </div>
            <div className="relative flex items-center justify-center">
                <img
                    src={formBG}
                    className="w-3/4 h-auto object-cover"
                    alt="Form Background"
                />
                <div className="absolute text-white space-y-2 flex top-[55%] transform -translate-y-1/2 left-1/2 -translate-x-1/2">
                    <h1 className=" text-black text-center text-4xl font-montserrat font-bold">
                        KEEP IN <h1 className="text-[#2E6D70]">TOUCH</h1>
                    </h1>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4 px-8">
                <div>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 text-[13px] py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#1d9597] sm:text-sm transform transition duration-500 ease-in-out"
                        placeholder="Name"
                        required
                    />
                </div>
                <div>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="mt-1 text-[13px] py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#1d9597] sm:text-sm transform transition duration-500 ease-in-out"
                        placeholder="Address"
                        required
                    />
                </div>
                <div>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-1 text-[13px] py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#1d9597] sm:text-sm transform transition duration-500 ease-in-out"
                        placeholder="Email"
                        required
                    />
                </div>
                <div>
                    <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        className="mt-1 text-[13px] py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#1d9597] sm:text-sm transform transition duration-500 ease-in-out"
                        placeholder="Your Company"
                        required
                    />
                </div>
                <div>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={3}
                        className="mt-1 text-[13px] py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#1d9597] sm:text-sm transform transition duration-500 ease-in-out"
                        placeholder="Message"
                        required
                    ></textarea>
                </div>
                <div className="flex w-full justify-end">
                    <button
                        type="submit"
                        className="border-2 border-gray-400 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-medium text-[13px] text-gray-500 px-8 py-2 rounded-md transform transition-colors duration-200"
                    >
                        SUBMIT
                    </button>
                </div>
            </form>
            <div className="font-montserrat font-semibold text-center bg-gray-600 mt-4 px-4 py-4 text-white text-2xl">
                CONTACT INFORMATION
            </div>
            <div className="flex mt-2 items-center space-x-2 px-4">
                <img src={phoneIcon} className="w-12 h-12" alt="phone-icon" />
                <div className="font-montserrat text-[16px] text-[#2E6D70] font-semibold">
                    PHONE NUMBER
                    <div className="flex justify-between w-full font-normal space-x-4">
                        {/* <p className="text-[13px] px-2 py-1 text-white bg-[#2E6D70]">
                            (+84) 902 492489
                        </p> */}
                        <p className="text-[13px] px-2 py-1 text-white bg-[#2E6D70]">
                            (+1) 857 701 8236
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex my-4 items-center space-x-2 px-4">
                <img src={emailIcon} className="w-14 h-12" alt="email-icon" />
                <div className="font-montserrat text-[16px] text-[#2E6D70] font-semibold">
                    EMAIL
                    <div className="flex font-normal space-x-4">
                        <p className="text-[13px] px-2 py-1 text-white bg-[#2E6D70]">
                            info@nutramed.us
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className="mt-4">
                <iframe
                    src={googleMap}
                    style={{ width: "100%", height: "250px" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div> */}
            {/* <div className="flex items-center space-x-4 justify-between">
                <div className="text-sm space-y-0">
                    <div
                        className="flex space-x-2 flex-row py-4 px-4 hover:bg-gray-100 hover:cursor-pointer transform transition-colors duration-300"
                        onClick={() => handleLocationClicked("head-office")}
                    >
                        <img
                            src={locationIcon}
                            className="w-6 h-6"
                            alt="location-icon"
                        />
                        <div className="flex space-y-2 flex-col">
                            <h1 className="font-bold text-[13px] font-montserrat">
                                HEAD OFFICE
                            </h1>
                            <p className="text-[13px] font-roboto">
                                BUILDING 577 NGUYEN THI THAP, TAN PHONG WARD,
                                DISTRICT 7, HO CHI MINH CITY
                            </p>
                        </div>
                    </div>
                    <div className="w-full h-[1px] bg-black"></div>
                    <div
                        className="flex space-x-2 py-4 px-4 hover:bg-gray-100 hover:cursor-pointer transform transition-colors duration-300 flex-row"
                        onClick={() => handleLocationClicked("factory-1")}
                    >
                        <img
                            src={locationIcon}
                            className="w-6 h-6"
                            alt="location-icon"
                        />
                        <div className="flex space-y-2 flex-col">
                            <h1 className="font-bold text-[13px] font-montserrat">
                                FACTORY 1
                            </h1>
                            <p className="text-[13px] font-roboto">
                                LOT B31-1, DOC 2 ROAD, PHU AN THANH INDUSTRIAL
                                ZONE, AN THANH COMMUNE, BEN LUC DISTRICT, LONG
                                AN PROVINCE
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex space-x-2 py-4 px-4 hover:bg-gray-100 hover:cursor-pointer transform transition-colors duration-300 flex-row"
                        onClick={() => handleLocationClicked("factory-2")}
                    >
                        <img
                            src={locationIcon}
                            className="w-6 h-6"
                            alt="location-icon"
                        />
                        <div className="flex space-y-2 flex-col">
                            <h1 className="font-bold text-[13px] font-montserrat">
                                FACTORY 2
                            </h1>
                            <p className="text-[13px] font-roboto">
                                LOT 17, N11 ROAD, DAT DO 1 INDUSTRIAL ZONE,
                                PHUOC LONG THO COMMUNE, DAT DO DISTRICT, BA RIA
                                - VUNG TAU PROVINCE
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="px-8">
                <div className="py-8">
                    <div className="flex items-center justify-center mb-2 relative">
                        <div className="absolute inset-x-0 top-1/2 border-t border-gray-400"></div>
                        <div className="relative flex flex-col text-center font-montserrat px-4 bg-white">
                            FROM
                            <span className="font-bold text-xl">NUTRAMED</span>
                        </div>
                    </div>
                    <div className="py-2">
                        <p className="text-gray-500 text-[13px] font-roboto text-balance text-center">
                            Our customers are at the heart of everything we do.
                            Ceaselessly maintaining an unwavering focus on
                            product excellence through persistent research and
                            development efforts, Nutramed is dedicated to
                            bringing you the best solutions for your growing
                            business.
                        </p>
                    </div>
                </div>
            </div>
            <div className="px-8">
                <div className="flex justify-center space-x-12">
                    <img src={globeIcon} className="w-8 h-8" alt="globe-icon" />
                    <img
                        src={facebookIcon}
                        className="w-8 h-8"
                        alt="facebook-icon"
                    />
                    <img
                        src={linkedinIcon}
                        className="w-8 h-8"
                        alt="linkedin-icon"
                        onClick={() =>
                            window.open(
                                "https://www.linkedin.com/company/nutramed-science-corporation/",
                                "_blank"
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
}
