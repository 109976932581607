import React from "react";
import nutramedLogo from "../assets/images/nutramed_logo_01.png";
import globeIcon from "../assets/icons/globe.png";
import linkedinIcon from "../assets/icons/linkedin.png";
import facebookIcon from "../assets/icons/facebook.png";

import facebookGrayIcon from "../assets/icons//facebook-gray.png";
import linkedinGrayIcon from "../assets/icons/linkedin-gray.png";

import phoneWhiteIcon from "../assets/icons/phone-white.png";
import mailWhiteIcon from "../assets/icons/mail-white.png";
import { useNavigate } from "react-router-dom";

export default function Contact() {
    const navigate = useNavigate();
    const handleLogoClick = () => {
        navigate("/");
        window.scrollTo(0, 0);
    };

    const handleAboutClick = () => {
        navigate("/about-us");
        window.scrollTo(0, 0);
    };

    const handleSolutionsClick = () => {
        navigate("/solutions");
        window.scrollTo(0, 0);
    };

    const handleProductsClick = () => {
        navigate("/products");
        window.scrollTo(0, 0);
    };

    const handleCSRClick = () => {
        navigate("/csr");
        window.scrollTo(0, 0);
    };

    const handleNewsroomClick = () => {
        navigate("/newsroom");
        window.scrollTo(0, 0);
    };

    const handleContactClick = () => {
        navigate("/contact-us");
        window.scrollTo(0, 0);
    };

    const [isSmall, setIsSmall] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth <= 800);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            className={`border-b-2 border-gray-800 flex bg-gray-900 py-48 
        2xl:pl-[148px] 2xl:pr-36 xl:pl-[148px] xl:pr-32 lg:pl-[80px] lg:pr-24 md:pl-[50px] md:pr-16 sm:pl-[30px] sm:pr-8 pl-[148px] pr-4 
        ${isSmall ? "flex-col space-y-12" : "flex-row space-x-24"}
        items-center justify-between`}
        >
            <div className="flex flex-col w-[14rem] -mt-20 font-montserrat justify-center items-center">
                <img
                    onClick={handleLogoClick}
                    src={nutramedLogo}
                    alt="Nutramed Logo"
                    className="hover:cursor-pointer w-[240px] h-[180px]"
                />
                <div className="space-y-2"></div>
            </div>
            <div className="flex text-white flex-row justify-between font-montserrat">
                <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-6 gap-4">
                    <div className="space-y-2">
                        <h3
                            onClick={handleAboutClick}
                            className="text-base pb-4 cursor-pointer font-montserrat font-semibold hover:underline hover:text-[#2E6D70]"
                        >
                            ABOUT
                        </h3>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Principles
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Core values
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Certificates
                        </p>
                    </div>
                    <div className="space-y-2">
                        <h4
                            onClick={handleSolutionsClick}
                            className="text-base pb-4 cursor-pointer font-montserrat font-semibold hover:underline hover:text-[#2E6D70]"
                        >
                            SOLUTIONS
                        </h4>
                        <p className="text-star font-roboto text-gray-400 font-light text-[13px]">
                            Capabilities
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Contract Manufacturing
                        </p>
                        <p className="text-star font-roboto text-gray-400 font-light text-[13px]">
                            Private Label
                        </p>
                    </div>
                    <div className="space-y-2">
                        <h5
                            onClick={handleProductsClick}
                            className="text-base pb-4 cursor-pointer font-montserrat font-semibold hover:underline hover:text-[#2E6D70]"
                        >
                            PRODUCTS
                        </h5>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Pharmaceuticals
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Cosmetics
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Medical Equipment
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Health Supplements
                        </p>
                    </div>
                    <div className="space-y-2">
                        <h6
                            onClick={handleCSRClick}
                            className="text-base pb-4 cursor-pointer font-montserrat font-semibold hover:underline hover:text-[#2E6D70]"
                        >
                            CSR
                        </h6>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Environment Action
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Gender Equality
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Workplace
                        </p>
                    </div>
                    <div className="space-y-2">
                        <h6
                            onClick={handleNewsroomClick}
                            className="text-base pb-4 cursor-pointer font-montserrat font-semibold hover:underline hover:text-[#2E6D70]"
                        >
                            NEWSROOM
                        </h6>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Latest News
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Corporate News
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Healths Tips
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            Featured Stories
                        </p>
                    </div>
                    <div className="space-y-2 w-[10rem]">
                        <h7
                            onClick={handleContactClick}
                            className="text-base pb-4 cursor-pointer font-montserrat font-semibold hover:underline hover:text-[#2E6D70]"
                        >
                            CONTACT
                        </h7>
                        <p className="text-start pt-4 font-roboto items-center justify-center text-gray-400 font-light text-[13px]">
                            info@nutramed.us
                        </p>
                        <p className="text-start font-roboto text-gray-400 font-light text-[13px]">
                            (+1) 857 701 8236
                        </p>
                        <div className="flex pt-2 space-x-4">
                            <img
                                src={facebookGrayIcon}
                                alt="Facebook Icon"
                                className="w-6 h-6 hover:cursor-pointer"
                            />
                            <img
                                src={linkedinGrayIcon}
                                alt="Linkedin Icon"
                                className="w-6 h-6 hover:cursor-pointer"
                                onClick={() =>
                                    window.open(
                                        "https://www.linkedin.com/company/nutramed-science-corporation/",
                                        "_blank"
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
