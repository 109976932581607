import React from "react";
import e_1 from "../assets/images/e-1.png";
import e_2 from "../assets/images/e-2.png";
import e_3 from "../assets/images/e-3.png";
import cers_1 from "../assets/images/cers-1.png";
import cers_2 from "../assets/images/cers-2.png";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
} from "@chakra-ui/react";

import { Link, useNavigate } from "react-router-dom";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function EighthPage() {
    const [isServiceSolutionsClicked, setIsServiceSolutionsClicked] =
        React.useState(false);
    const [isProductsClicked, setIsProductsClicked] = React.useState(false);

    const handleServiceSolutionsClick = () => {
        setIsServiceSolutionsClicked(!isServiceSolutionsClicked);
    };

    const handleProductsClick = () => {
        setIsProductsClicked(!isProductsClicked);
    };

    const [isCoreValuesClick, setIsCoreValuesClick] = React.useState(false);

    const handleCoreValuesClick = () => {
        setIsCoreValuesClick(!isCoreValuesClick);
    };

    const [activeIndex, setActiveIndex] = React.useState(0);

    const navigate = useNavigate();

    return (
        <div className="flex flex-col">
            <div
                className="flex mt-16 mb-12
            2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="flex items-center">
                    <img
                        src={e_1}
                        alt="e-1"
                        className="w-1/2"
                        style={{ transform: "scale(1)" }}
                    />

                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInRight"
                        className="flex w-1/2 shadow-xl px-8 font-montserrat py-6 flex-col justify-center h-full space-y-6"
                    >
                        <h1 className="text-end text-black text-[24px]">
                            Core Values
                        </h1>
                        <div className="space-y-1">
                            <h1 className=" text-black text-[14px] font-bold">
                                Innovation and Excellence
                            </h1>
                            <h2 className=" text-gray-500 font-roboto text-[14px]">
                                Relentlessly improve and innovate in all aspects
                                of our work.
                            </h2>
                        </div>
                        <div className="space-y-1">
                            <h1 className=" text-black text-[14px] font-bold">
                                Integrity and Responsibility
                            </h1>
                            <h2 className=" text-gray-500 font-roboto text-[14px]">
                                Overate with the highest standards and foster
                                trust among all stakeholders.
                            </h2>
                        </div>
                        <div className="space-y-1">
                            <h1 className=" text-black text-[14px] font-bold">
                                Customer Focus
                            </h1>
                            <h2 className=" text-gray-500 font-roboto text-[14px]">
                                Exceed customer expectations in every
                                interaction.
                            </h2>
                        </div>
                        <div className="space-y-1">
                            <h1 className=" text-black text-[14px] font-bold">
                                Collaboration
                            </h1>
                            <h2 className=" text-gray-500 font-roboto text-[14px]">
                                Promote a diverse and inclusive workplace
                                reflective of our global footprint.
                            </h2>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>

            <div
                animateOnce
                animateIn="fadeInLeft"
                className="flex shadow-md shadow-gray-200 pt-12 pb-36 mt-8
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    className="top-0 shadow-xl w-[56rem] items-center py-8 space-y-4 justify-center flex flex-col"
                >
                    <h1 className="text-black text-center text-[24px] font-bold font-montserrat mb-8">
                        CERTIFICATES
                    </h1>
                    <div className="flex space-x-2 px-12 justify-evenly">
                        <div className="flex text-justify space-y-4 flex-1 flex-col items-center">
                            <img
                                src="https://dhti.vn/wp-content/uploads/2022/12/gmp-certificate-7-e1670923959309.png"
                                className="w-1/3 object-cover"
                                alt="cers-1"
                            />
                            <div className="text-center">
                                <h1 className="text-black font-roboto text-[16px] font-bold">
                                    GMP Certified
                                </h1>
                                <p className="text-gray-500 text-center px-4 font-roboto text-[14px]">
                                    Our facility follows strict GMP standards
                                    for all production steps.
                                </p>
                            </div>
                        </div>
                        <div className="flex text-justify space-y-4 flex-1 flex-col items-center">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/NSF_International_logo.svg/1200px-NSF_International_logo.svg.png"
                                className="w-1/3 object-cover"
                                alt="cers-2"
                            />
                            <div className="text-center">
                                <h1 className="text-black font-roboto text-[16px] font-bold">
                                    NSF Certified
                                </h1>
                                <p className="text-gray-500 text-center px-4 font-roboto text-[14px]">
                                    Our products meet rigorous safety and
                                    quality standards.
                                </p>
                            </div>
                        </div>
                        <div className="flex text-justify space-y-4 flex-1 flex-col items-center">
                            <img
                                src="https://www.npanational.org/wp-content/uploads/2018/05/Natural-Seals-e1527268403989.png"
                                className="w-1/3 object-cover"
                                alt="cers-3"
                            />
                            <div className="text-center">
                                <h1 className="text-black font-roboto text-[16px] font-bold">
                                    NPA Certified
                                </h1>
                                <p className="text-gray-500 text-center px-4 font-roboto text-[14px]">
                                    Our NPA-certified products are natural,
                                    effective, and high-quality.
                                </p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
                <img src={e_2} alt="e-2" className="w-[35%]" />
            </div>
            <div
                animateOnce
                animateIn="fadeInUp"
                className="flex pt-40 pb-40 space-x-8 h-[36rem] items-center justify-between
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <img src={e_3} alt="e-3" className="w-[65%]" />
                <ScrollAnimation animateOnce animateIn="fadeInRight">
                    <Accordion
                        defaultIndex={0}
                        className="w-full py-4 flex flex-col bg-opacity-70"
                    >
                        <AccordionItem>
                            <h2>
                                <AccordionButton
                                    _hover={{ bg: "gray.200" }}
                                    onClick={() => setActiveIndex(0)}
                                >
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Services
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-start space-x-4 justify-between">
                                    <Box className="flex-1 space-y-2 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="small"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            We provide contract manufacturing
                                            and private labeling services for
                                            pharmaceuticals and nutraceuticals.
                                        </Text>
                                        <div className="flex justify-end">
                                            <button
                                                onClick={() => {
                                                    navigate("/solutions");
                                                    window.scrollTo(0, 0);
                                                }}
                                                className="text-gray-500 text-[12px] border-2 border-gray-300 px-2 py-1 rounded-lg hover:border-[#2E6D70] italic font-roboto hover:text-[#2E6D70] transform duration-300"
                                            >
                                                Learn more
                                            </button>
                                        </div>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton
                                    _hover={{ bg: "gray.200" }}
                                    onClick={() => setActiveIndex(1)}
                                >
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Products
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-start space-x-4 justify-between">
                                    <Box className="flex-1 space-y-2 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="small"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            Our products cover indications
                                            including women’s health, men’s
                                            health, dermatology, urology,
                                            vitamins, and more.
                                        </Text>
                                        <div className="flex justify-end">
                                            <button
                                                onClick={() => {
                                                    navigate("/products");
                                                    window.scrollTo(0, 0);
                                                }}
                                                className="text-gray-500 text-[12px] border-2 border-gray-300 px-2 py-1 rounded-lg hover:border-[#2E6D70] italic font-roboto hover:text-[#2E6D70] transform duration-300"
                                            >
                                                Learn more
                                            </button>
                                        </div>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton
                                    _hover={{ bg: "gray.200" }}
                                    onClick={() => setActiveIndex(2)}
                                >
                                    <Box
                                        flex="1"
                                        textAlign="left"
                                        fontSize="lg"
                                        fontWeight="bold"
                                        fontFamily="Roboto"
                                    >
                                        Capabilities
                                    </Box>
                                    <AccordionIcon boxSize={6} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={2}>
                                <Box className="flex text-start space-x-4 justify-between">
                                    <Box className="flex-1 space-y-2 text-gray-500 flex flex-col">
                                        <Text
                                            fontSize="small"
                                            fontFamily="Roboto"
                                            mt={-2}
                                        >
                                            We boasts 2 GMP-certified factories
                                            with state-of-the-art laboratories,
                                            home to a hub of multidisciplinary
                                            experts.
                                        </Text>
                                        <div className="flex justify-end">
                                            <button
                                                onClick={() => {
                                                    navigate("/solutions");
                                                    window.scrollTo(0, 0);
                                                }}
                                                className="text-gray-500 text-[12px] border-2 border-gray-300 px-2 py-1 rounded-lg hover:border-[#2E6D70] italic font-roboto hover:text-[#2E6D70] transform duration-300"
                                            >
                                                Learn more
                                            </button>
                                        </div>
                                    </Box>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </ScrollAnimation>
            </div>
        </div>
    );
}
