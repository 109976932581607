import React, { useState } from "react";
import piracufImage from "../assets/images/piracuf-image.png";
import checkIcon from "../assets/icons/check.png";
import ProductCard from "./ProductCard";
import piracuf_1 from "../assets/images/piracuf-image-1.png";
import piracuf_2 from "../assets/images/piracuf-image-2.png";
import piracuf_3 from "../assets/images/piracuf-image-3.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/swiper-bundle.css";
import "../components/ProductDetails.css";

export default function ProductDetails() {
    const [product, setProduct] = useState({
        product_name: "",
        benefits: "",
        contraindications: "",
        disclaimers: "",
        dosage_and_administration: "",
        expiry: "",
        ingredients: "",
        packaging: "",
        possible_side_effects: "",
        precautions: "",
        product_description: "",
        product_details: "",
        storage: "",
        category: "",
        user_type: "",
        dosage_form: "",
        thumbnail_url: "",
    });

    const productId = window.location.pathname.split("/")[2];

    React.useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_EXPRESS_BACKEND_URL}/products/${productId}`
                );
                const data = await response.json();
                setProduct(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchProduct();
    }, []);

    const parseIfJson = (field) => {
        if (typeof field === "string") {
            try {
                return JSON.parse(field);
            } catch (error) {
                console.warn(`Unable to parse field: ${field}`, error);
                return field;
            }
        }
        return field;
    };

    product.contraindications = parseIfJson(product.contraindications);

    const renderAsAlist = (field) => {
        // if ((typeof field !== "string" && field !== null) || field === "") {
        //     return null;
        // }
        // return field?.split("\n").map((fieldItem, index) => (
        //     <li className="text-sm text-gray-600" key={index}>
        //         {fieldItem}
        //     </li>
        // ));
        return (
            <p className="text-gray-700">
                {field}
            </p>
        )
    };

    const renderAsAListWithCheckIcon = (field) => {
        if (typeof field !== "string" || field === "") {
            return null;
        }

        const fieldItems = field.split("\n");

        return (
            <ul className="text-gray-600">
                {fieldItems.map((fieldItem, index) => (
                    <li
                        key={index}
                        className="flex items-center mb-1 space-x-2"
                    >
                        <img src={checkIcon} alt="check" className="w-4 h-4" />
                        <span>{fieldItem}</span>
                    </li>
                ))}
            </ul>
        );
    };

    const renderAsAListAndTableForIngredients = (field) => {
        if (typeof field !== "string" || field === "") {
            return null;
        }

        const ingredients = field.split("\n");

        return (
            <table className="w-2/3 border-collapse font-roboto text-sm text-gray-700">
                {/* <thead>
                    <tr className="border-b">
                        <th className="p-2 text-left">#</th>
                        <th className="p-2 text-left">Ingredient</th>
                    </tr>
                </thead> */}
                <tbody>
                    {ingredients.map((ingredient, index) => (
                        <tr key={index} className="border-b">
                            <td className="p-2 text-center bg-[#0D9149] bg-opacity-20">
                                {index + 1}
                            </td>
                            <td className="p-2 bg-[#E5F3EC]">{ingredient}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="bg-white space-y-8 pb-16 rounded-md">
            <div className="flex flex-col shadow-xl">
                <div className="flex space-y-36 pb-48 flex-col">
                    <div className="relative">
                        <img
                            src={product.thumbnail_url}
                            alt="Piracuf Syrup"
                            className="opacity-5"
                        />
                        <div className="absolute inset-0 w-full h-full">
                            <div className="">
                                <div className="flex justify-between h-full px-48 pt-12 items-center space-x-12">
                                    <div className="w-[40%]">
                                        {/* <Swiper
                                            className="mySwiper"
                                            spaceBetween={8}
                                            slidesPerView={1}
                                            pagination={{ clickable: true }}
                                            navigation
                                            loop={true}
                                            modules={[Navigation, Pagination]}
                                        >
                                            {products.images.map(
                                                (image, index) => (
                                                    <SwiperSlide
                                                        className="flex bg-gray-200 py-16 px-16 rounded-2xl justify-center items-center"
                                                        key={index}
                                                    >
                                                        <div className="">
                                                            <img
                                                                src={image}
                                                                alt={`piracuf-${index}`}
                                                                className=""
                                                                onClick={() =>
                                                                    setSelectedImage(
                                                                        image
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            )}
                                        </Swiper> */}
                                        <img
                                            src={product.thumbnail_url}
                                            alt="Piracuf Syrup"
                                        />
                                    </div>
                                    <div className="w-1/2 space-y-20">
                                        <div className="space-y-2">
                                            <h1 className="text-[42px] font-montserrat font-bold text-[#0D9149]">
                                                {product.product_name}
                                            </h1>
                                            <p className="text-base font-montserrat text-gray-700">
                                                {product.product_details}
                                            </p>
                                            <ul className="text-base pt-4 text-black">
                                                {renderAsAListWithCheckIcon(
                                                    product.benefits
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-x-20 px-48 flex">
                                    <div className="space-y-4 w-1/2">
                                        <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                            Ingredients
                                        </h2>
                                        {/* <table className="w-[90%] font-roboto border border-gray-300">
                                            <tbody>
                                                {product?.ingredients.map(
                                                    (ingredient, index) => (
                                                        <tr
                                                            key={index}
                                                            className="border-b bg-[#9ca49e] border-gray-100"
                                                        >
                                                            <td className="py-2 border-r px-4 text-center text-sm text-white">
                                                                {index + 1}
                                                            </td>
                                                            <td className="py-2 px-4 text-sm text-white">
                                                                {
                                                                    ingredient.ingredients_name
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table> */}
                                        {renderAsAListAndTableForIngredients(
                                            product.ingredients
                                        )}
                                    </div>
                                    <div className="w-1/2 grid font-roboto grid-cols-1 sm:grid-cols-1 gap-x-4 space-y-4">
                                        {/* <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Product Summary
                                            </h2>
                                            <ul className="text-sm text-black">
                                                {renderAsAlist(
                                                    product.benefits
                                                )}
                                            </ul>
                                        </div> */}
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                User Type
                                            </h2>
                                            <p className="text-sm text-gray-700">
                                                {product.user_type}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Contraindications
                                            </h2>
                                            <p className="text-sm text-black">
                                                {renderAsAlist(
                                                    product.contraindications
                                                )}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Dosage and Administration
                                            </h2>
                                            <p className="text-sm text-black">
                                                {renderAsAlist(
                                                    product.dosage_and_administration
                                                )}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Disclaimer
                                            </h2>
                                            <p className="text-sm text-black">
                                                {renderAsAlist(
                                                    product.disclaimers
                                                )}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Storage
                                            </h2>
                                            <p className="text-sm text-gray-700">
                                                {product.storage}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Packaging
                                            </h2>
                                            <p className="text-sm text-gray-700">
                                                {product.packaging}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Dosage Form
                                            </h2>
                                            <p className="text-sm text-gray-700">
                                                {product.dosage_form}
                                            </p>
                                        </div>
                                        <div className="space-y-2">
                                            <h2 className="text-[16px] font-montserrat font-bold text-[#0D9149]">
                                                Expiry
                                            </h2>
                                            <p className="text-sm text-gray-700">
                                                {product.expiry}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-x-20 px-48 flex"></div>
            </div>
        </div>
    );
}
