import React from "react";
import homepageMobile3 from "../assets/images/homepage-mobile-3.png";

import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";

export default function SecondMobile() {
    const navigate = useNavigate();
    return (
        <div className="h-[58rem] pt-24">
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820753/nutramed/homepage-mobile-3_jnld48.png"
                    alt="background"
                    className=""
                />
                <div className="absolute left-8 top-[35%] space-y-4 inset-0 flex flex-col w-[36rem]">
                    <h1 className="flex font-montserrat text-white text-[24px] font-semibold">
                        ABOUT NUTRAMED
                    </h1>
                    <p className="flex w-[18rem] line-clamp-3 font-roboto text-white text-[12px] font-light">
                        Nutramed Science Corporation (Nutramed) is a rapidly
                        expanding leader in the research, production, and
                        distribution of pharmaceuticals, nutraceuticals,
                        cosmetics, and medical equipment.
                    </p>
                    <div className="flex">
                        <button
                            onClick={() => {
                                navigate("/about");
                                window.scrollTo(0, 0);
                            }}
                            className="border text-[10px] hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-medium text-white px-4 py-2 rounded-md transform transition-colors duration-200"
                        >
                            LEARN MORE
                        </button>
                    </div>
                </div>
            </div>
            <div className="px-8 bg-gray-100 py-12 space-y-4 flex flex-col justify-center items-center">
                <h1 className="font-montserrat text-black text-2xl font-semibold">
                    Capabilities
                </h1>
                <div className="border-b border-b-white w-full"></div>
                <h2 className="font-roboto text-center text-gray-500 text-xs font-normal">
                    Leveraging 2 state-of-the-art manufacturing facilities and
                    laboratories, Nutramed provides R&D expertise, contract
                    manufacturing, testing, and private labeling.
                </h2>
                <button
                    onClick={() => {
                        navigate("/capabilities");
                        window.scrollTo(0, 0);
                    }}
                    className="border border-gray-400 text-[10px] hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-medium text-gray-500 px-4 py-2 rounded-md transform transition-colors duration-200"
                >
                    LEARN MORE
                </button>
            </div>
            <div className="px-8 bg-gray-200 py-12 space-y-4 flex flex-col justify-center items-center">
                <h1 className="font-montserrat text-black text-2xl font-semibold">
                    Products
                </h1>
                <div className="border-b border-b-white w-full"></div>
                <h2 className="font-roboto text-center text-gray-500 text-xs font-normal">
                    Nutramed specializes in producing medicines, health
                    supplements, nutraceuticals, and cosmetics.
                </h2>
                <button
                    onClick={() => {
                        navigate("/products");
                        window.scrollTo(0, 0);
                    }}
                    className="border border-gray-400 text-[10px] hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-medium text-gray-500 px-4 py-2 rounded-md transform transition-colors duration-200"
                >
                    LEARN MORE
                </button>
            </div>
        </div>
    );
}
