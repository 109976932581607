import React from "react";
import abc1 from "../assets/images/abc-01.png";
import abc2 from "../assets/images/abc-02.png";
import abc3 from "../assets/images/abc-03.png";
import solutionsHeader from "../assets/images/solutions-header.png";
import lineCurve from "../assets/images/line-curve.png";
import factorySide from "../assets/images/factory-side.png";
import aaa_1 from "../assets/images/aaa-01.png";
import aaa_2 from "../assets/images/aaa-02.png";
import solutionsMobile1 from "../assets/images/solutions-mobile-1.png";
import { Link } from "react-router-dom";

export default function TwelfthMobile() {
    return (
        <div>
            <div className="relative bg-white font-montserrat">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722833335/nutramed/csr-header-mobile_hzchla.png"
                    alt="background"
                    className=""
                />
                <div className="absolute items-start text-white space-y-2 flex flex-col top-1/2 transform -translate-y-1/2 left-6">
                    <h1
                        className=" text-white text-4xl font-montserrat font-bold"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    >
                        CSR
                    </h1>
                </div>
            </div>
            <div className="text-center w-full flex self-center flex-col items-center font-roboto justify-center">
                <div className="flex px-8 space-y-2 my-4 flex-col">
                    <h1 className="text-2xl font-montserrat font-bold text-center mt-4">
                        NUTRAMED CORPORATION
                    </h1>
                    <h2 className="text-lg font-montserrat font-semibold text-center">
                        BRINGING SCIENCE TO LIFE - BRING VALUES TO COMMUNITY
                    </h2>
                </div>
            </div>
            <img src={lineCurve} alt="line-curve" className="w-full px-12" />
            <div className="flex mt-8 space-x-4 pr-4">
                <img
                    src={abc1}
                    className="w-[50%] h-auto object-cover"
                    alt="abc-01"
                />
                <div className="flex flex-col space-y-6 justify-center py-2 items-end">
                    <h1 className="font-montserrat text-end text-black font-semibold text-base">
                        Environment Action
                    </h1>
                    <p className="font-roboto text-end text-gray-600 text-[10px]">
                        Nutramed recognizes the crucial importance of using the
                        least amount of plastic possible in our product
                        manufacturing and packaging. Replacing plastic bottles
                        with ecofriendly glass alternatives is one of our
                        unwavering efforts to create value and reduce
                        environmental impact. This transition not only helps
                        minimize plastic waste generation but also creates added
                        value for our products, aligning with Nutramed's
                        commitment to sustainability.
                    </p>
                </div>
            </div>
            <div className="flex space-x-4 pl-4">
                <div className="flex flex-col space-y-6 justify-center py-2 items-start">
                    <h1 className="font-montserrat text-start text-black font-semibold text-base">
                        Contract Manufacturing
                    </h1>
                    <p className="font-roboto text-start text-gray-600 text-[10px]">
                        We continuously strive to foster a dynamic workplace
                        where each individual is valued. Beyond providing equal
                        opportunities for all employees, Nutramed actively
                        promotes gender equality and a culture of equity. By
                        empowering women to unleash their potential and
                        contribute to the company's overall growth, we believe
                        that our efforts can extend beyond the workplace and
                        contribute to broader gender equality within Vietnamese
                        society.
                    </p>
                </div>
                <img
                    src={abc2}
                    className="w-[50%] h-auto object-cover"
                    alt="abc-02"
                />
            </div>
            <div className="flex space-x-2 pr-4">
                <img
                    src={abc3}
                    className="w-[50%] h-auto object-cover"
                    alt="abc-03"
                />
                <div className="flex flex-col space-y-6 justify-center py-2 items-end">
                    <h1 className="font-montserrat text-end text-black font-semibold text-base">
                        Private Label
                    </h1>
                    <p className="font-roboto text-end text-gray-600 text-[10px]">
                        At Nutramed, we cultivate a safe and healthy working
                        environment. The health and safety of our employees are
                        paramount to us at Nutramed. We understand our
                        responsibility to create a safe, healthy, and supportive
                        work environment, providing comprehensive welfare
                        benefits to our workforce. We remain committed to
                        fostering a workplace where employees feel empowered to
                        innovate and contribute while prioritizing their
                        well-being and respecting individual values.
                    </p>
                </div>
            </div>
            <div className="flex px-8 mt-12 justify-between">
                <div className="space-y-6">
                    <p className="font-montserrat text-xl font-bold">
                        Commitment to
                    </p>
                    <h1 className="font-montserrat text-2xl font-bold">
                        Sustainable Development
                    </h1>
                    <p className="font-roboto text-sm text-gray-500">
                        As a leading pharmaceutical and nutraceutical
                        corporation, Nutramed pledges to continue pursuing
                        sustainable practices well into the future.
                    </p>
                    <p className="font-roboto text-sm text-gray-500">
                        The company will invest in ecofriendly manufacturing
                        technologies, utilize recycled materials, and raise
                        employee awareness about the significance of
                        environmental protection. Nutramed will further engage
                        in community support initiatives, contributing to the
                        development of a sustainable society.
                    </p>
                    <p className="font-montserrat text-xl font-bold">
                        ARTICLES
                    </p>
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-col justify-between shadow-xl pb-4">
                            <img
                                className="h-1/2"
                                src="https://www.simplypsychology.org/wp-content/uploads/postmodern-feminism.jpeg"
                                alt="place-holder"
                            />
                            <div className="space-y-1 px-6 pt-4">
                                <p className="font-montserrat text-[10px]">
                                    Corporate News
                                </p>
                                <h1 className="text-[13px] font-montserrat font-bold">
                                    EMPOWERING WOMEN EMPLOYEES AT NUTRAMED
                                </h1>
                                <p className="font-montserrat text-[10px]">
                                    July 24, 2024 | 06:15 am GMT+7
                                </p>
                                <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                                    Pharmaceutical Nutramed Corporation has
                                    selected Dat Do Industrial Park in Vietnam
                                    as the strategic location for its new
                                    Phytogen plant. The state-of-the-art
                                    facility will leverage the park's
                                    world-class infrastructure and prime
                                    location to drive growth and innovation in
                                    the healthcare sector.
                                </p>
                            </div>
                            <div className="flex pt-4 px-8 justify-end w-full">
                                <Link
                                    to="/sample-details"
                                    className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>

                        <div className="flex flex-col justify-between shadow-xl pb-4">
                            <img
                                className="h-1/2"
                                src="https://png.pngtree.com/thumb_back/fh260/background/20231106/pngtree-underwater-plastic-pollution-an-urgent-call-for-waste-reduction-and-marine-image_13778546.png"
                                alt="place-holder"
                            />
                            <div className="space-y-1 px-6 pt-4">
                                <p className="font-montserrat text-[10px]">
                                    Sustainability
                                </p>
                                <h1 className="text-[13px] font-montserrat line-clamp-2 font-bold">
                                    MANUFACTURING WASTE REDUCED AT 12%
                                </h1>
                                <p className="font-montserrat text-[10px]">
                                    July 24, 2024 | 06:15 am GMT+7
                                </p>
                                <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                                    Pharmaceutical leader Nutramed Corporation
                                    is taking bold steps to address Vietnam's
                                    pressing environmental challenges. With a
                                    focus on plastic reduction, sustainable
                                    practices, and community engagement,
                                    Nutramed is demonstrating its commitment to
                                    a greener future for the nation.
                                </p>
                            </div>
                            <div className="flex pt-4 px-8 justify-end w-full">
                                <Link
                                    to="/sample-details"
                                    className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-4 pb-8 space-y-4 mt-12">
                <p className="text-center font-montserrat text-xl font-semibold">
                    Nutramed’s Endeavor
                </p>
                <h1 className="text-center font-montserrat text-2xl font-bold">
                    TO GLOBAL ENGAGEMENT
                </h1>
                <div className="text-start items-center flex flex-col justify-center pt-4 px-8 space-y-4">
                    <div className="border-l-2 flex flex-col items-center justify-center pl-8 border-[#2E6D70] space-y-4 italic">
                        <p className="text-sm text-gray-800 font-roboto font-normal">
                            By fostering a culture of collaboration and shared
                            responsibility, Nutramed aims to be a driving force
                            in building a healthier planet for generations to
                            come.
                        </p>
                        <p className="text-sm text-gray-800 pb-4 font-roboto font-normal">
                            We hereby invite our stakeholders—employees,
                            customers, suppliers, and community partners—to join
                            us in this journey of creating a bright future for
                            the planet. Together, we can follow sustainable
                            practices across the supply chain.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
