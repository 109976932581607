import React from "react";
import ProductCard from "./ProductCard";
import searchIcon from "../assets/icons/search_icon.png";
import menuIcon from "../assets/icons/menu.png";

import "animate.css/animate.min.css";

export default function ProductList() {
    const [products, setProducts] = React.useState([]);

    React.useEffect(() => {
        async function fetchProducts() {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_EXPRESS_BACKEND_URL}/products`
                );
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        }

        fetchProducts();
    }, []);

    // const products = [
    //     {
    //         product_name: "Piracuf Syrup",
    //         product_description:
    //             "Piracuf Syrup: A herbal blend for cough relief and soothing sore throats, formulated with 12 traditional herbs for natural respiratory comfort.",
    //         product_details:
    //             "Piracuf Syrup is an herbal supplement for cough relief, and soothing sore throat. Formulated with a blend of 12 traditional herbs, Piracuf Syrup provides natural relief for respiratory discomfort in the throat.",
    //         benefits: ["Pulmonology, Respiratory function"],
    //         contraindications: [
    //             "Hypersensitivity or allergy to Piracuf Syrup’s components.",
    //             "Pregnancy.",
    //             "Individuals with diarrhea, spleen or stomach disease, or yin deficiency.",
    //         ],
    //         dosage_and_administration: [
    //             "Children 1-6 years old: 5-10 ml, 2-3 times per day.",
    //             "Children 6-12 years old: 10 ml, 2-3 times per day.",
    //             "Children over 12 years old and adults: 15 ml, 2-3 times per day.",
    //         ],
    //         disclaimer: [
    //             "Consult a healthcare professional before use.",
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 1 bottle x 120ml",
    //         ingredients: [
    //             "Clerodenron cytophyllum Turcz (Bản lam căn - Baihuadan)",
    //             "Rhizoma Belamcandae chinensis (Xa can - Shegan)",
    //             "Platycodon grandiflorum (Cát cánh - Jigeng)",
    //             "Fructus Trichosanthis (Qua lâu - Gualou)",
    //             "Fructus Gardeniae (Chi từ - Zhizi)",
    //         ],
    //     },
    //     {
    //         product_name: "Fecal Pro Calci Vitamin K2-MK7",
    //         product_description:
    //             "Fecalpro Calci Vitamin K2 - MK7: Promotes strong bones with organic calcium, Vitamin K2-MK7, and Vitamin D, ideal for those at risk of rickets and needing calcium supplementation.",
    //         product_details:
    //             "Fecalpro Calci Vitamin K2 - MK7: An Effective Solution for Strong Bones. Fecalpro Calci Vitamin K2 - MK7 is designed for individuals at risk of rickets and those in need of calcium supplementation. This product contains organic calcium, Vitamin K2-MK7, and Vitamin D, promoting strong bones from inside out.",
    //         benefits: ["Orthopedics"],
    //         contraindications: [
    //             "Hypersensitivity or allergy to its components.",
    //             "Bleeding disorders.",
    //             "On warfarin or other anticoagulant therapy.",
    //             "Hypercalcemia or hypercalciuria.",
    //             "Kidney stones, urinary stones, or kidney disease.",
    //         ],
    //         dosage_and_administration: [
    //             "Children 1-3 years old: 10 ml per day.",
    //             "Children 3-10 years old: 10 ml twice daily.",
    //             "Children over 10 years old and adults, including pregnant and lactating women: 10 ml three times daily.",
    //         ],
    //         disclaimer: [
    //             "Consult a healthcare professional before use.",
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 20 x 10 ml sachets",
    //         expiry: "36 months from the date of manufacture",
    //         ingredients: [
    //             "Calcium gluconate",
    //             "Calcium lactate",
    //             "L-Lysine hydrochloride",
    //             "Zinc gluconate",
    //             "Thymomodulin",
    //         ],
    //     },
    //     {
    //         product_name: "Novasitol Forte",
    //         product_description:
    //             "Novasitol Forte: Supports fertility and reproductive balance, certified and packaged to standard specifications, suitable for women planning to conceive without severe digestive disturbances or interactions with caffeine.",
    //         product_details:
    //             "Novasitol Forte - A supplement that supports fertility and reproductive balance. The product is certified to meet standards and is packaged according to standard specifications, suitable for women planning to conceive. Easy to use, it does not cause severe digestive disturbances and does not interact with caffeine-containing beverages.",
    //         benefits: ["Infertility"],
    //         contraindications: [
    //             "Hypersensitivity or allergy to Novasitol Forte’s components.",
    //         ],
    //         dosage_and_administration: [
    //             "2 tablets daily after meals, 1 tablet in the morning and 1 tablet in the evening.",
    //         ],
    //         precautions: [
    //             "Do not take the product with carbonated water, caffeine, or tea.",
    //             "Avoid alcohol and tobacco.",
    //             "Possible mild gastrointestinal disturbances. These mild disturbances should subside within 3-4 days.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 3 blisters x 10 tablets",
    //         expiry: "36 months from the date of manufacture",
    //         ingredients: [
    //             "Myo-Inositol",
    //             "Vitex agnus castus extract",
    //             "N-Acetyl L-Cysteine",
    //             "Magnesium (Magnesium oxide)",
    //             "Zinc (Zinc sulfate monohydrate)",
    //         ],
    //         possible_side_effects: ["Mild gastrointestinal disturbances."],
    //     },
    //     {
    //         product_name: "Lactova",
    //         product_description: "Lactova: Superior Lactation Support",
    //         product_details:
    //             "Lactova is an herbal supplement formulated specifically for breastfeeding women, tackling three key challenges: promoting milk production, improving milk flow, and supporting digestive health for both mom and baby. This synergistic blend combines five traditionally used herbs from Indian, Chinese, and European medicine, all recognized for their galactagogic properties, which naturally stimulate milk production.",
    //         benefits: ["Obstetrics and Gynecology"],
    //         dosage_and_administration: [
    //             "Take 1-2 capsules twice daily, before meals, with a glass of warm water.",
    //         ],
    //         precautions: [
    //             "Patients with hypersensitivity or allergy to Lactova’s components.",
    //             "Consult a healthcare professional before use if you are pregnant, breastfeeding, or taking any medications.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "This product is a dietary supplement and is not intended to replace a healthy diet and lifestyle.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 3/6/9 blisters x 10 hard capsules",
    //         expiry: "36 months from the date of manufacture. The date of manufacture and expiry date are indicated on the packaging.",
    //         ingredients: [
    //             "300 mg Shatavari extract (Asparagus racemosus wild, Indian Asparagus Extract) (Thiên môn chùm – Tiandong)",
    //             "50 mg Fenugreek extract (Cỏ cà ri – Huluba)",
    //             "30 mg Dandelion extract (Bồ công anh – Pugongying)",
    //             "10 mg Milk Thistle extract (Kế sữa – Ruji)",
    //             "10 mg Glycyrrhiza glabra extract (Licorice Extract) (Cam thảo – gancao)",
    //         ],
    //     },
    //     {
    //         product_name: "OSA-GASTRO",
    //         product_description:
    //             "OSA-GASTRO: A natural supplement developed to support the management of gastric and duodenal ulcers in adults.",
    //         product_details:
    //             "OSA-GASTRO is a natural supplement developed to support the management of gastric and duodenal ulcers in adults. With a diverse range of natural ingredients, OSA-GASTRO provides a comprehensive enzyme-based approach, working through six different mechanisms to heal and protect the gastric and duodenal mucosa.",
    //         benefits: ["Gastroenterology"],
    //         contraindications: [
    //             "Pregnancy and lactation.",
    //             "Kidney failure.",
    //             "Hypersensitivity or allergy to Osa-Gastro components.",
    //         ],
    //         dosage_and_administration: [
    //             "Adults: 15 ml twice daily, 2-3 hours after meals.",
    //         ],
    //         precautions: [
    //             "Consult a healthcare professional before use if you are taking any medications.",
    //             "Do not use continuously for more than 2 weeks as it may cause diarrhea.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 20 sachets x 15 ml",
    //         expiry: "36 months from the date of manufacture.",
    //         ingredients: [
    //             "2000 mg Extract of Hedyotis capitellata (Dạ cẩm – Touhuacao)",
    //             "1200 mg Extract of Ampelopsis cantoniensis (Chè dây – Baihuasheshecao)",
    //             "1000 mg Extract of Ardisia silvestris Pitard (Lá khôi – Zijinniu)",
    //             "750 mg Radix Sophorae Flavescentis (Khổ sâm – Kushen)",
    //             "500 mg Curcuma longa (Nghệ vàng – Jianghuang)",
    //             "250 mg Pericarpium Citri Reticulatae perenn (Trần bì – Chenpi)",
    //             "200 mg Curcuma zedoaria (Nghệ đen – Ezhu)",
    //             "145 mg Magnesium hydroxide (equivalent to 60 mg Magnesium)",
    //         ],
    //     },
    //     {
    //         product_name: "OSAFEMIC",
    //         product_description:
    //             "OSAFEMIC: A specialized health supplement for children aged 4 and older and adults facing iron deficiency anemia.",
    //         product_details:
    //             "OSAFEMIC is a specialized health supplement for children aged 4 and older and adults facing iron deficiency anemia. With a diverse composition, OSAFEMIC contains essential nutrients such as iron, inulin, lysine, zinc, and vitamin B that effectively reduce the risk of anemia.",
    //         benefits: ["Iron deficiency, Anemia"],
    //         contraindications: [
    //             "Hypersensitivity or allergy to Osafemic’s components.",
    //             "Iron deficiency anemia not caused by iron deficiency (thalassemia).",
    //         ],
    //         dosage_and_administration: [
    //             "Children over 4 years and adults, including pregnant and breastfeeding women: 10 ml once daily.",
    //             "Take 30 minutes after meals.",
    //         ],
    //         precautions: [
    //             "Consult a healthcare professional before use if you are taking any medications.",
    //             "Do not take OSAFEMIC with other iron-containing supplements.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 20 sachets x 10 ml",
    //         expiry: "36 months from the date of manufacture.",
    //         ingredients: [
    //             "100 mg Iron (III) hydroxide polymaltose complex (equivalent to 31.15 mg Elemental Iron)",
    //             "50 mg Inulin",
    //             "50 mg L-Lysine hydrochloride (equivalent to 40 mg L-Lysine)",
    //             "20 mg Zinc gluconate (equivalent to 2.87 mg Elemental Zinc)",
    //             "2 mg Vitamin B6 (Pyridoxin hydrochloride)",
    //             "2 mg Vitamin B1 (Thiamine hydrochloride)",
    //             "2 mg Vitamin B2 (Sodium riboflavin 5'-phosphate)",
    //             "2 mg Vitamin B3 (Nicotinamide)",
    //             "400 mcg Folic acid",
    //             "5 mcg Vitamin B12 (Cyanocobalamin)",
    //         ],
    //     },
    //     {
    //         product_name: "OVUMRICH FORTE",
    //         product_description:
    //             "OVUMRICH Forte: A dietary supplement specifically formulated for women struggling with infertility, supporting reproductive health in women undergoing assisted reproductive treatment (ART).",
    //         product_details:
    //             "OVUMRICH Forte is a dietary supplement specifically formulated for women struggling with infertility. It contains a synergistic blend of essential nutrients, including myo-inositol, N-acetyl cysteine, and herbal extracts, which supports reproductive health in women who undergo assisted reproductive treatment (ART).",
    //         benefits: ["Infertility"],
    //         contraindications: [
    //             "Hypersensitivity or allergy to Ovumrich Forte’s components.",
    //             "Do not take the product with carbonated beverages, coffee, or tea.",
    //             "Avoid alcohol and tobacco.",
    //         ],
    //         dosage_and_administration: [
    //             "1 sachet per day or as directed by a healthcare provider. Do not take with meals.",
    //             "Consume collaboratively with other medications to stimulate ovulation and improve egg quality as directed by a healthcare provider.",
    //         ],
    //         possible_side_effects: [
    //             "Mild gastrointestinal disturbances may occur upon initial use and typically subside within 3-4 days.",
    //         ],
    //         precautions: [
    //             "Consult a healthcare professional before use if you are pregnant, breastfeeding, or taking any medications.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 20/30/60/90 sachets, each sachet containing 5g.",
    //         expiry: "36 months from the date of manufacture.",
    //         ingredients: [
    //             "3000 mg Myo-Inositol",
    //             "50 mg Acetyl-N-Cysteine",
    //             "300 mg Asparagus racemosus extract",
    //             "100 mg Vitex agnus-castus extract",
    //             "500 mcg Folic acid",
    //             "5 mcg Vitamin B12",
    //             "1000 IU Vitamin D3",
    //             "80 IU Vitamin E",
    //             "100 mcg Chromium",
    //             "Tá dược - Excipients",
    //         ],
    //     },
    //     {
    //         product_name: "MOTIXEED",
    //         product_description:
    //             "Motixeed: Protect Your Sperm from Oxidative Damage, supporting male fertility by protecting sperm from oxidative damage.",
    //         product_details:
    //             "MOTIXEED is a unique dietary supplement formulated to support male fertility by protecting sperm from oxidative damage. It contains a powerful blend of antioxidants, including selenium, Coenzyme Q10, Vitamin C, and Vitamin E, which support the neutralization of free radicals and safeguard sperm integrity.",
    //         benefits: ["Reproductive Endocrinology and Infertility"],
    //         contraindications: [
    //             "Do not take this product on an empty stomach.",
    //             "Do not take this product with tea or coffee.",
    //             "Hypersensitivity or allergy to Motixeed’s components.",
    //             "Not for individuals under 18 years of age.",
    //             "Do not exceed the recommended dosage.",
    //         ],
    //         possible_side_effects: [
    //             "Mild gastrointestinal disturbances may occur upon initial use and typically subside within 3-4 days.",
    //         ],
    //         dosage_and_administration: [
    //             "1-2 sachets per day, mixed with 200 ml of water. Take 1-2 hours after meals.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to replace a healthy diet and lifestyle.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.",
    //             "Consult a healthcare professional before using if you have any underlying health conditions.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 10/20/30/40/50/60 sachets x 5g.",
    //         expiry: "36 months from the date of manufacture. The date of manufacture and expiry date are indicated on the packaging.",
    //         ingredients: [
    //             "1700 mg L-Carnitine fumarate",
    //             "500 mg Acetyl-L-Carnitine HCl",
    //             "100 mg Withania somnifera extract",
    //             "80 mg Tribulus terrestris extract",
    //             "20 mg Zinc",
    //             "200 mcg Folic acid",
    //             "50 mcg Selenium",
    //             "5 mcg Vitamin B12",
    //             "50 mg Vitamin C",
    //             "40 IU Vitamin E",
    //             "20 mg Coenzyme Q10",
    //             "Tá dược - Excipients",
    //         ],
    //     },
    //     {
    //         product_name: "SINUKA",
    //         product_description:
    //             "Sinuka: A health supplement formulated to support sinus health against sneezing, congestion, sinus pressure, runny nose, and itchy watery eyes.",
    //         product_details:
    //             "Sinuka is a health supplement formulated to support sinus health against sneezing, congestion, sinus pressure, runny nose, and itchy watery eyes. Developed based on the ancient Chinese herbal formula Jia Wei Xin Yi San, using seven traditional herbs, Sinuka helps eliminate bacteria, fungus, and viruses while reducing swelling and congestion. This natural composition makes it gentle and suitable for long-term use.",
    //         additional_information: [
    //             "Natural Ingredients: Sinuka is made with 100% natural extracts from seven precious herbs, including Sinusitis flower, Horsehead, Angelica dahurica root, Honeysuckle, Cloves, Siler indragauzhandae rhizome, and Cocklebur fruit.",
    //             "Advanced Processing: The combination of natural ingredients with modern processing technology optimizes Sinuka's effectiveness in alleviating symptoms and preventing the recurrence of sinusitis and rhinitis.",
    //             "Multi-Action Formula: Sinuka works through a comprehensive mechanism to aid the reduction of sneezing, congestion, sinus pressure, runny nose, and itchy watery eyes.",
    //         ],
    //         benefits: ["Pulmonology, Respiratory function"],
    //         contraindications: [
    //             "Hypersensitivity or allergy to Sinuka’s components.",
    //             "Pregnancy and lactation.",
    //             "Do not use if symptoms worsen; consult a doctor.",
    //         ],
    //         dosage_and_administration: [
    //             "Adults: 1-2 tablets, 2-3 times per day (morning and evening) before meals with warm water.",
    //             "In severe cases, 9 tablets per day as directed by a healthcare professional.",
    //             "Use at least 2-3 months for the best results.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 3/6/9 blisters x 10 tablets.",
    //         expiry: "36 months from the date of manufacture.",
    //         ingredients: [
    //             "100 mg Sinusitis flower extract (Tân di hoa -)",
    //             "100 mg Horsehead extract (Ké đầu ngựa – Muzei)",
    //             "100 mg Angelicae dahuricae radix extract (Bạch chỉ - Baizhi)",
    //             "100 mg Honeysuckle extract (Kim ngân hoa – Jinyinhua)",
    //             "50 mg Cloves extract (Đinh hương – Dingxiang)",
    //             "50 mg Siler indragauzhandae rhizome extract (Phòng phong – Fangfeng)",
    //             "50 mg Cocklebur fruit extract (Cóc mẳn – Cangerzi)",
    //             "Tá dược vừa đủ 1 viên",
    //         ],
    //     },
    //     {
    //         product_name: "PALITON CAPSULES",
    //         product_description:
    //             "Paliton Capsules: A traditional Chinese herbal formula designed to combat fatigue, weakness, and poor appetite in adults.",
    //         product_details:
    //             "Paliton Capsules, a traditional Chinese herbal formula, are designed to combat fatigue, weakness, and poor appetite in adults. This natural supplement, made with a blend of ten time-tested herbs, helps replenish qi (vital energy), promoting overall well-being and a renewed sense of vitality.",
    //         benefits: ["Food Supplement"],
    //         contraindications: [
    //             "Hypersensitivity or allergy to any component of Paliton Capsules.",
    //             "Pregnancy.",
    //             "Individuals with spleen or stomach disease, diarrhea, or bloating.",
    //             "Individuals with yin deficiency, fire exuberance, or phlegm vomiting.",
    //         ],
    //         dosage_and_administration: [
    //             "Adults: 2 capsules, 1-2 times per day after meals.",
    //         ],
    //         precautions: [
    //             "Do not take this product with products containing iron or Veratrum nigrum.",
    //             "Consult a healthcare professional before use if you are taking any medication.",
    //         ],
    //         disclaimer: [
    //             "This product is a dietary supplement and is not intended to be a substitute for medication.",
    //             "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.",
    //         ],
    //         storage:
    //             "Store in a cool, dry place away from sunlight. Keep below 30°C. Keep out of reach of children.",
    //         packaging: "Box of 3 blisters x 10 capsules.",
    //         expiry: "36 months from the date of manufacture.",
    //         ingredients: [
    //             "450 mg Hỗn hợp cao tương đương thảo mộc thô",
    //             "750 mg Radix Angelicae Sinensis (Đương quy - Danggui)",
    //             "750 mg Radix Rehmanniae glutinosae praeparata (Thục địa – Shudihuang)",
    //             "500 mg Radix Paeoniae lactiflorae (Bạch thược – Baishao)",
    //             "500 mg Radix Astragali membranacea (Hoàng kỳ - Huangqi)",
    //             "500 mg Radix Codonopsis Pilosella (Đảng sâm – Dangshen)",
    //             "500 mg Poria cocos (Phục linh – Fuling)",
    //             "500 mg Rhizoma Atractylodis macrocephalae (Bạch truật - Baizhu)",
    //             "250 mg Rhizoma Ligustici wali chai (Xuyên khung – Chuanxiong)",
    //             "125 mg Radix Glycyrrhizae (Cam thảo – Gancao)",
    //             "125 mg Cortex Cinnamomi (Quế nhục - Rougui)",
    //         ],
    //     },
    // ];

    const [searchQuery, setSearchQuery] = React.useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredProducts = products?.filter((product) => {
        return product.product_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
    });

    const [isSelectOpen, setIsSelectOpen] = React.useState(false);

    const handleSelectOpen = () => {
        setIsSelectOpen(!isSelectOpen);
    };

    return (
        <div className="px-48 pt-8 pb-20 space-y-8">
            <h1 className="text-4xl font-bold font-montserrat text-[#2E6D70]">
                Products
            </h1>
            <div className="flex flex-col justify-start items-start space-x-4">
                <div className="w-full flex flex-row space-x-4 items-end">
                    <div
                        className="flex flex-col hover:bg-gray-300 hover:cursor-pointer"
                        onClick={handleSelectOpen}
                    >
                        <img
                            src={menuIcon}
                            alt="Menu Icon"
                            className="w-8 h-8"
                        />
                    </div>
                    <div className="w-full flex items-end space-x-4">
                        <input
                            type="text"
                            placeholder="Search products"
                            className="mt-1 py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#1d9597] transform transition duration-500 ease-in-out"
                            onChange={handleSearchChange}
                        />
                        <img
                            src={searchIcon}
                            alt="Search Icon"
                            className="w-8 h-8"
                        />
                    </div>
                </div>
                {isSelectOpen && (
                    <div className="p-4 pt-8">
                        <ul className="space-y-6">
                            <li className="text-gray-500 hover:text-[#2E6D70] hover:cursor-pointer font-bold font-montserrat text-base">
                                Pharmaceuticals
                            </li>
                            <li className="text-gray-500 hover:text-[#2E6D70] hover:cursor-pointer font-bold font-montserrat text-base">
                                Cosmetics
                            </li>
                            <li className="text-gray-500 hover:text-[#2E6D70] hover:cursor-pointer font-bold font-montserrat text-base">
                                Medical Equipment
                            </li>
                            <li className="text-gray-500 hover:text-[#2E6D70] hover:cursor-pointer font-bold font-montserrat text-base">
                                Health Supplements
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <div className="grid animate__animated animate__fadeInUp md:grid-cols-3 px-24 gap-4 md:gap-6 lg:gap-12">
                {filteredProducts.map((product, index) => (
                    <ProductCard key={index} product={product} />
                ))}
            </div>
        </div>
    );
}
