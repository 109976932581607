import React from "react";
import newsroomHeader from "../assets/images/newsroom-final-header.png";
import { Link } from "react-router-dom";

export default function ThirteenthMobile() {
    return (
        <div>
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820895/nutramed/newsroom-final-header_sojaxu.png"
                    alt="newsroom-header"
                    className=""
                />
                <div className="absolute items-start text-white space-y-2 flex flex-col top-1/2 transform -translate-y-1/2 left-6">
                    <h1 className=" text-black text-4xl font-montserrat font-bold">
                        NEWSROOM
                    </h1>
                </div>
            </div>
            <div className="flex py-8 px-4 items-center">
                <h1 className="text-[15px] w-[11rem] font-montserrat font-bold">
                    LATEST NEWS
                </h1>
                <div className="border-b-2 border-black w-full"></div>
            </div>
            <div className="flex flex-col justify-between shadow-xl pb-4">
                <img
                    className="h-1/2"
                    src="https://media.istockphoto.com/id/1460762245/photo/modern-style-hi-rise-blue-glass-building-exterior-with-transparent-wall-3d-render.jpg?s=612x612&w=0&k=20&c=a5V0dIHugs_dfzKDHP4NP6WR3lc00vA0wDpaMBrai3o="
                    alt="place-holder"
                />
                <div className="space-y-1 px-6 pt-4">
                    <p className="font-montserrat text-[10px]">
                        Corporate News
                    </p>
                    <h1 className="text-[13px] font-montserrat font-bold">
                        FROM HERBAL INSPIRATION TO A MULTINATIONAL
                        PHARMACEUTICAL CORPORATION
                    </h1>
                    <p className="font-montserrat text-[10px]">
                        July 24, 2024 | 06:15 am GMT+7
                    </p>
                    <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                        Nutramed Science Corporation, a leading pharmaceutical
                        company with roots in Vietnam's rich herbal heritage,
                        has emerged as a global force in healthcare. With a
                        steadfast commitment to quality, innovation, and
                        community well-being, Nutramed has expanded its reach
                        while staying true to its origins.
                    </p>
                </div>
                <div className="flex pt-4 px-8 justify-end w-full">
                    <Link
                        to="/sample-details"
                        className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                    >
                        Read More
                    </Link>
                </div>
            </div>

            <div className="flex flex-col justify-between shadow-xl pb-4">
                <img
                    className="h-1/2"
                    src="https://i.ytimg.com/vi/W05RZvwAsM0/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGFMgWyhlMA8=&rs=AOn4CLDj9PLsNoJ29Q02aw0OASQ3xId2jA"
                    alt="place-holder"
                />
                <div className="space-y-1 px-6 pt-4">
                    <p className="font-montserrat text-[10px]">
                        Corporate News
                    </p>
                    <h1 className="text-[13px] font-montserrat font-bold">
                        NUTRAMED'S PHYTOGEN PLANT FINDS IDEAL HOME IN DAT DO
                        INDUSTRIAL PARK
                    </h1>
                    <p className="font-montserrat text-[10px]">
                        July 24, 2024 | 06:15 am GMT+7
                    </p>
                    <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                        Pharmaceutical Nutramed Corporation has selected Dat Do
                        Industrial Park in Vietnam as the strategic location for
                        its new Phytogen plant. The state-of-the-art facility
                        will leverage the park's world-class infrastructure and
                        prime location to drive growth and innovation in the
                        healthcare sector.
                    </p>
                </div>
                <div className="flex pt-4 px-8 justify-end w-full">
                    <Link
                        to="/sample-details"
                        className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                    >
                        Read More
                    </Link>
                </div>
            </div>

            <div className="flex flex-col justify-between shadow-xl pb-4">
                <img
                    className="h-1/2"
                    src="https://www.unisys.com/siteassets/images/about-unisys/e-s-g-hero.jpg"
                    alt="place-holder"
                />
                <div className="space-y-1 px-6 pt-4">
                    <p className="font-montserrat text-[10px]">
                        Sustainability
                    </p>
                    <h1 className="text-[13px] font-montserrat font-bold">
                        ENVIRONMENTAL PROTECTION AND SUSTAINABLE DEVELOPMENT IN
                        NUTRAMED
                    </h1>
                    <p className="font-montserrat text-[10px]">
                        July 24, 2024 | 06:15 am GMT+7
                    </p>
                    <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                        Pharmaceutical leader Nutramed Corporation is taking
                        bold steps to address Vietnam's pressing environmental
                        challenges. With a focus on plastic reduction,
                        sustainable practices, and community engagement,
                        Nutramed is demonstrating its commitment to a greener
                        future for the nation.
                    </p>
                </div>
                <div className="flex pt-4 px-8 justify-end w-full">
                    <Link
                        to="/sample-details"
                        className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                    >
                        Read More
                    </Link>
                </div>
            </div>

            <div className="flex flex-col justify-between shadow-xl pb-4">
                <img
                    className="h-1/2"
                    src="https://www.shutterstock.com/image-photo/medical-blank-bottle-pills-vitamins-600nw-2116414733.jpg"
                    alt="place-holder"
                />
                <div className="space-y-1 px-6 pt-4">
                    <p className="font-montserrat text-[10px]">
                        Corporate News
                    </p>
                    <h1 className="text-[13px] font-montserrat font-bold">
                        NUTRAMED’S ECO-FRIENDLY GLASS ALTERNATIVES
                    </h1>
                    <p className="font-montserrat text-[10px]">
                        July 24, 2024 | 06:15 am GMT+7
                    </p>
                    <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                        Nutramed Science Corporation, a leading pharmaceutical
                        company with roots in Vietnam's rich herbal heritage,
                        has emerged as a global force in healthcare. With a
                        steadfast commitment to quality, innovation, and
                        community well-being, Nutramed has expanded its reach
                        while staying true to its origins.
                    </p>
                </div>
                <div className="flex pt-4 px-8 justify-end w-full">
                    <Link
                        to="/sample-details"
                        className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                    >
                        Read More
                    </Link>
                </div>
            </div>

            <div className="flex flex-col justify-between shadow-xl pb-4">
                <img
                    className="h-1/2"
                    src="https://www.simplypsychology.org/wp-content/uploads/postmodern-feminism.jpeg"
                    alt="place-holder"
                />
                <div className="space-y-1 px-6 pt-4">
                    <p className="font-montserrat text-[10px]">
                        Corporate News
                    </p>
                    <h1 className="text-[13px] font-montserrat font-bold">
                        EMPOWERING WOMEN EMPLOYEES AT NUTRAMED
                    </h1>
                    <p className="font-montserrat text-[10px]">
                        July 24, 2024 | 06:15 am GMT+7
                    </p>
                    <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                        Pharmaceutical Nutramed Corporation has selected Dat Do
                        Industrial Park in Vietnam as the strategic location for
                        its new Phytogen plant. The state-of-the-art facility
                        will leverage the park's world-class infrastructure and
                        prime location to drive growth and innovation in the
                        healthcare sector.
                    </p>
                </div>
                <div className="flex pt-4 px-8 justify-end w-full">
                    <Link
                        to="/sample-details"
                        className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                    >
                        Read More
                    </Link>
                </div>
            </div>

            <div className="flex flex-col justify-between shadow-xl pb-4">
                <img
                    className="h-1/2"
                    src="https://png.pngtree.com/thumb_back/fh260/background/20231106/pngtree-underwater-plastic-pollution-an-urgent-call-for-waste-reduction-and-marine-image_13778546.png"
                    alt="place-holder"
                />
                <div className="space-y-1 px-6 pt-4">
                    <p className="font-montserrat text-[10px]">
                        Sustainability
                    </p>
                    <h1 className="text-[13px] font-montserrat line-clamp-2 font-bold">
                        MANUFACTURING WASTE REDUCED AT 12%
                    </h1>
                    <p className="font-montserrat text-[10px]">
                        July 24, 2024 | 06:15 am GMT+7
                    </p>
                    <p className="font-roboto pt-4 text-[12px] line-clamp-3">
                        Pharmaceutical leader Nutramed Corporation is taking
                        bold steps to address Vietnam's pressing environmental
                        challenges. With a focus on plastic reduction,
                        sustainable practices, and community engagement,
                        Nutramed is demonstrating its commitment to a greener
                        future for the nation.
                    </p>
                </div>
                <div className="flex pt-4 px-8 justify-end w-full">
                    <Link
                        to="/sample-details"
                        className="hover:text-[#2E6D70] underline italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                    >
                        Read More
                    </Link>
                </div>
            </div>
        </div>
    );
}
