import React from "react";

export default function Footer() {
    const [isSmall, setIsSmall] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth <= 800);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            className={`bg-gradient-to-l from-gray-900 to-gray-800 from text-white font-montserrat py-4 flex flex-row items-center justify-between 
        2xl:px-48 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4
        ${isSmall ? "flex-col" : ""}`}
        >
            <p className="text-[16px] font-semibold">
                NUTRAMED USA CORPORATION
            </p>
            <p className="text-[13px]">
                &copy; Copyright 2016 All rights reserved
            </p>
        </div>
    );
}
