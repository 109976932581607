import React from "react";
import abc1 from "../assets/images/abc-01.png";
import abc2 from "../assets/images/abc-02.png";
import abc3 from "../assets/images/abc-03.png";
import "animate.css/animate.compat.css";
import { useNavigate } from "react-router-dom";

export default function ThirdMobile() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col bg-white space-y-2 py-4 items-center justify-center">
            <h1 className="font-montserrat text-black text-[20px] font-semibold">
                WE ARE HERE TO ELEVATE
            </h1>
            <h2 className="font-montserrat text-white bg-gray-900 px-4 py-2 text-[20px] font-bold">
                YOUR SUCCESS
            </h2>
            <div className="flex flex-col px-8 pb-4 space-y-2 text-center items-center">
                <p className="font-roboto px-1 self-center text-black text-[12px]">
                    Nutramed provides comprehensive turnkey solutions to meet
                    diverse customer needs. We are committed to becoming a
                    source of expertise for our customers and providing a true
                    partnership in the development and growth of their
                    businesses.
                </p>
            </div>
            <div
                animateOnce
                animateIn="fadeInRight"
                duration={1}
                className="flex space-x-4"
            >
                <img
                    src={abc1}
                    className="w-[50%] h-auto object-cover"
                    alt="abc-01"
                />
                <div className="flex flex-col space-y-6 justify-center py-2 items-start">
                    <h1 className="font-montserrat text-start text-black font-semibold text-base">
                        Research & Development
                    </h1>
                    <p className="font-roboto text-start text-gray-600 text-[10px]">
                        Nutramed’s team of scientists drives innovation through
                        cutting-edge R&D, continuously formulating new medicines
                        and nutraceuticals.
                    </p>
                    <button
                        onClick={() => {
                            navigate("/solutions");
                            window.scrollTo(0, 0);
                        }}
                        className="border border-gray-400 text-[10px] hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-medium text-gray-500 px-4 py-2 rounded-md transform transition-colors duration-200"
                    >
                        LEARN MORE
                    </button>
                </div>
            </div>
            <div
                animateOnce
                animateIn="fadeInLeft"
                duration={1.25}
                className="flex space-x-4"
            >
                <div className="flex flex-col space-y-6 pl-4 justify-center py-2 items-start">
                    <h1 className="font-montserrat text-start text-black font-semibold text-base">
                        Contract Manufacturing
                    </h1>
                    <p className="font-roboto text-start text-gray-600 text-[10px]">
                        We offer contract manufacturing services to produce
                        customized orders while ensuring high-quality products.
                    </p>
                    <button
                        onClick={() => {
                            navigate("/solutions");
                            window.scrollTo(0, 0);
                        }}
                        className="border border-gray-400 text-[10px] hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-medium text-gray-500 px-4 py-2 rounded-md transform transition-colors duration-200"
                    >
                        LEARN MORE
                    </button>
                </div>
                <img
                    src={abc2}
                    className="w-[50%] h-auto object-cover"
                    alt="abc-02"
                />
            </div>
            <div
                animateOnce
                animateIn="fadeInRight"
                duration={1.5}
                className="flex space-x-4"
            >
                <img
                    src={abc3}
                    className="w-[50%] h-auto object-cover"
                    alt="abc-03"
                />
                <div className="flex flex-col space-y-6 justify-center py-2 items-start">
                    <h1 className="font-montserrat text-start text-black font-semibold text-base">
                        Private Label
                    </h1>
                    <p className="font-roboto text-start text-gray-600 text-[10px]">
                        Nutramed provides private label services with fast turn
                        around time and guidance from our team of experts.
                    </p>
                    <button
                        onClick={() => {
                            navigate("/solutions");
                            window.scrollTo(0, 0);
                        }}
                        className="border border-gray-400 text-[10px] hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-medium text-gray-500 px-4 py-2 rounded-md transform transition-colors duration-200"
                    >
                        LEARN MORE
                    </button>
                </div>
            </div>
        </div>
    );
}
