import React from "react";
import SCX_01 from "../assets/icons/SCX-01.png";
import SCX_02 from "../assets/icons/SCX-02.png";
import SCX_03 from "../assets/icons/SCX-03.png";
import xyz_02 from "../assets/images/xyz-02.png";
import xyz_03 from "../assets/images/xyz-03.png";
import xyz_04 from "../assets/images/xyz-04.png";
import ReactCardFlip from "react-card-flip";
import "animate.css/animate.compat.css";

import ScrollAnimation from "react-animate-on-scroll";

export default function FourthPage() {
    const [isCard1Click, setIsCard1Click] = React.useState(false);
    const [isCard2Click, setIsCard2Click] = React.useState(false);
    const [isCard3Click, setIsCard3Click] = React.useState(false);

    const handleCardClick = (index) => {
        if (index === 1) {
            setIsCard1Click(!isCard1Click);
        } else if (index === 2) {
            setIsCard2Click(!isCard2Click);
        } else if (index === 3) {
            setIsCard3Click(!isCard3Click);
        }
    };

    const [isCard1Hover, setIsCard1Hover] = React.useState(false);
    const [isCard2Hover, setIsCard2Hover] = React.useState(false);
    const [isCard3Hover, setIsCard3Hover] = React.useState(false);

    return (
        <div className="relative pt-20 bg-white font-montserrat">
            <img
                src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722823441/nutramed/xyz-02_favqdb.png"
                alt="background"
                className="object-cover h-full w-full"
            />
            <div className="absolute z-10 inset-1 flex flex-col items-center justify-center">
                <div className="flex flex-col justify-center  items-center space-y-12">
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={0.5}
                        className="text-white font-bold
                         2xl:text-[42px] xl:text-[36px] lg:text-[30px] md:text-[24px] sm:text-[18px] text-[14px]"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    >
                        WHAT MAKES NUTRAMED DIFFERENT
                    </ScrollAnimation>
                    <div
                        className="flex justify-between space-x-8 flex-row 
                    2xl:w-[60rem] xl:w-[50rem] lg:w-[45rem] md:w-[40rem] sm:w-[30rem]"
                    >
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeOutLeft"
                            className="flex-1 relative"
                        >
                            {!isCard1Hover && (
                                <div
                                    onMouseEnter={() => {
                                        setIsCard1Hover(true);
                                    }}
                                    className={`flex rounded-bl-xl w-full hover:cursor-default hover:bg-white bg-opacity-90 flex-col py-12 justify-center items-center bg-gray-200 animate__animated animate__fadeIn`}
                                >
                                    <img
                                        src={SCX_01}
                                        alt="Resource & Capabilities"
                                        className="2xl:w-[200px] 2xl:h-[214px]
                                        xl:w-[180px] xl:h-[195px]
                                        lg:w-[160px] lg:h-[175px]
                                        md:w-[120px] md:h-[135px]
                                        sm:w-[105px] sm:h-[120px]"
                                    />
                                    <h1 className="text-center font-montserrat text-black text-[16px] font-semibold">
                                        R&D Expertise
                                    </h1>
                                </div>
                            )}
                            {isCard1Hover && (
                                <div
                                    onMouseLeave={() => {
                                        setIsCard1Hover(false);
                                    }}
                                    className={`flex rounded-bl-xl hover:cursor-default space-y-4 flex-col h-full justify-start pt-12 items-center bg-[#2E6D70] animate__animated animate__fadeIn`}
                                >
                                    <h1 className="text-center pb-6 font-montserrat text-white text-[16px] font-semibold">
                                        R&D Expertise
                                    </h1>
                                    <div className="border-b-2 w-full"></div>
                                    <div>
                                        <p className="px-8 flex flex-col pt-8 font-montserrat text-white text-center text-[14px] font-normal">
                                            Nutramed leverages cutting-edge R&D
                                            to develop a comprehensive portfolio
                                            (pharmaceuticals, supplements, and
                                            health foods) to meet diverse market
                                            demands.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </ScrollAnimation>

                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeOut"
                            className="flex-1"
                        >
                            {!isCard2Hover && (
                                <div
                                    onMouseEnter={() => {
                                        setIsCard2Hover(true);
                                    }}
                                    className={`flex w-full hover:cursor-default hover:bg-white bg-opacity-90 flex-col py-12 justify-center items-center bg-gray-200 animate__animated animate__fadeIn`}
                                >
                                    <img
                                        src={SCX_02}
                                        alt="Resource & Capabilities"
                                        className="2xl:w-[200px] 2xl:h-[214px]
                                        xl:w-[180px] xl:h-[195px]
                                        lg:w-[160px] lg:h-[175px]
                                        md:w-[120px] md:h-[135px]
                                        sm:w-[105px] sm:h-[120px]"
                                    />
                                    <h1 className="text-center font-montserrat text-black text-[16px] font-semibold">
                                        Innovation across continents
                                    </h1>
                                </div>
                            )}
                            {isCard2Hover && (
                                <div
                                    onMouseLeave={() => {
                                        setIsCard2Hover(false);
                                    }}
                                    className="flex hover:cursor-default space-y-4 flex-col h-full justify-start pt-12 items-center bg-[#2E6D70] animate__animated animate__fadeIn"
                                >
                                    <h1 className="text-center pb-6 font-montserrat text-white text-[16px] font-semibold">
                                        Innovation across continents
                                    </h1>
                                    <div className="border-b-2 w-full"></div>
                                    <div>
                                        <p className="px-8 pt-8 font-montserrat text-white text-center text-[14px] font-normal">
                                            Our R&D office in Vancouver, Canada,
                                            closely collaborates with a
                                            manufacturing team in Vietnam to
                                            ensure a smooth transition from
                                            design concept to mass production.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </ScrollAnimation>

                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeOutRight"
                            className="flex-1"
                        >
                            {!isCard3Hover && (
                                <div
                                    onMouseEnter={() => {
                                        setIsCard3Hover(true);
                                    }}
                                    className={`flex rounded-br-xl w-full hover:cursor-default hover:bg-white bg-opacity-90 flex-col py-12 justify-center items-center bg-gray-200 animate__animated animate__fadeIn`}
                                >
                                    <img
                                        src={SCX_03}
                                        alt="Resource & Capabilities"
                                        className="2xl:w-[200px] 2xl:h-[214px]
                                        xl:w-[180px] xl:h-[195px]
                                        lg:w-[160px] lg:h-[175px]
                                        md:w-[120px] md:h-[135px]
                                        sm:w-[105px] sm:h-[120px]"
                                    />
                                    <h1 className="text-center font-montserrat text-black text-[16px] font-semibold">
                                        Quality Assurance
                                    </h1>
                                </div>
                            )}
                            {isCard3Hover && (
                                <div
                                    onMouseLeave={() => {
                                        setIsCard3Hover(false);
                                    }}
                                    className="flex rounded-br-xl hover:cursor-default space-y-4 flex-col h-full justify-start pt-12 items-center bg-[#2E6D70] animate__animated animate__fadeIn"
                                >
                                    <h1 className="text-center pb-6 font-montserrat text-white text-[16px] font-semibold">
                                        Quality Assurance
                                    </h1>
                                    <div className="border-b-2 w-full"></div>
                                    <div>
                                        <p className="px-8 pt-8 font-montserrat text-white text-center text-[14px] font-normal">
                                            At Nutramed, we guarantee the
                                            highest product quality by strictly
                                            adhering to industry standards, from
                                            raw material selection to production
                                            and quality control.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    );
}
