import React from 'react'
import acc_1 from '../assets/images/acc-1.png';
import acc_2 from '../assets/images/acc-2.png';
import acc_3 from '../assets/images/acc-3.png';

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export default function SeventhMobile() {
    return (
        <div className='px-8 pb-24 space-y-4'>
            <ScrollAnimation animateOnce animateIn='fadeInUp' className='shadow-lg p-4 rounded-xl space-y-2'>
                <h1 className='text-xl font-semibold font-montserrat'>Our Mission</h1>
                <div className='border-b w-full border-[#2E6D70]'></div>
                <p className='text-sm flex space-y-2 flex-col text-gray-600 font-roboto text-justify'>
                    <span>Prioritize and guarantee transparency, honesty, and ethical conduct in all aspects of our operations.</span>
                    <span>Continuously uphold rigorous standards to drive innovation and deliver trusted healthcare solutions to people around the world.</span>
                    <span>Be a leader in environmental stewardship and sustainable practices throughout our product lifecycle.</span>
                </p>
                <img src={acc_1} alt='acc_1' className='w-full rounded-xl' />
            </ScrollAnimation>
            <ScrollAnimation animateOnce animateIn='fadeInUp' className='shadow-lg p-4 rounded-xl space-y-2'>
                <h1 className='text-xl font-semibold font-montserrat'>Our Vision</h1>
                <div className='border-b w-full border-[#2E6D70]'></div>
                <p className='text-sm text-gray-600 font-roboto text-justify'>Research and develop more high-quality products, increase production capacity, and expand our global footprint to bring health to the whole world.</p>
                <img src={acc_2} alt='acc_2' className='w-full rounded-xl' />
            </ScrollAnimation>
            <ScrollAnimation animateOnce animateIn='fadeInUp' className='shadow-lg p-4 rounded-xl space-y-2'>
                <h1 className='text-xl font-semibold font-montserrat'>Our Values</h1>
                <div className='border-b w-full border-[#2E6D70]'></div>
                <p className='text-sm text-gray-600 font-roboto text-justify'>
                    With unwavering dedication and strategic investments, Nutramed is poised to ascend to the forefront of Vietnam's pharmaceutical industry.
                </p>
                <img src={acc_3} alt='acc_3' className='w-full rounded-xl' />
            </ScrollAnimation>
        </div>
    )
}
