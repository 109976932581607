import React from "react";
import abc_1 from "../assets/images/abc-01.png";
import abc_2 from "../assets/images/abc-02.png";
import abc_3 from "../assets/images/abc-03.png";

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";

export default function ThirdPage() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col bg-white space-y-2 py-8 pt-36 items-center justify-center">
            <ScrollAnimation
                animateIn="fadeInDown"
                animateOut="fadeOutUp"
                className="font-montserrat text-black text-[24px] font-semibold"
            >
                WE ARE HERE TO ELEVATE
            </ScrollAnimation>
            <ScrollAnimation
                animateIn="fadeInDown"
                animateOut="fadeOutUp"
                className="font-montserrat text-white bg-gray-900 px-4 py-2 text-[24px] font-bold"
            >
                YOUR SUCCESS
            </ScrollAnimation>
            <ScrollAnimation
                animateIn="fadeInDown"
                animateOut="fadeOutUp"
                className="flex pt-4 pb-6 flex-col items-center"
            >
                <p className="font-roboto text-center px-12 text-black text-[14px]">
                    Nutramed provides comprehensive turnkey solutions to meet
                    diverse customer needs.
                </p>
                <p className="font-roboto text-center px-12 text-black text-[14px]">
                    We are committed to becoming a source of expertise for our
                    customers and providing a true partnership in the
                    development and growth of their businesses.
                </p>
            </ScrollAnimation>
            <div className="flex px-48">
                <ScrollAnimation
                    animateOut="fadeOutLeft"
                    animateIn="fadeInLeft"
                    duration={1}
                    className="flex h-full flex-1 flex-col items-center text-center justify-center"
                >
                    <img
                        src={abc_1}
                        className="w-[60%] object-cover"
                        alt="placeholder"
                    />
                    <div
                        className="2xl:px-16 xl:px-12 lg:px-8 md:px-4 sm:px-2 
                    2xl:h-[10rem] flex flex-col justify-between pt-4"
                    >
                        <h1 className="font-montserrat text-center px-2 text-black font-semibold text-[16px]">
                            Research & Development
                        </h1>
                        <div className="pt-2">
                            <p className="font-roboto px-2 py-2 pb-4 text-center text-black text-[14px]">
                                Our team of scientists spurs innovation through
                                cutting-edge R&D to formulate new products.
                            </p>
                            <div className="flex justify-center px-2">
                                <button
                                    onClick={() => {
                                        navigate("/solutions");
                                        window.scrollTo(0, 0);
                                    }}
                                    className="border-2 border-gray-400 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 px-8 py-2 rounded-md transform transition-colors duration-200"
                                >
                                    LEARN MORE
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>

                <div className="border-l-2 border-gray-200 h-auto"></div>

                <ScrollAnimation
                    animateOut="fadeOutUp"
                    animateIn="fadeInUp"
                    duration={1}
                    className="flex h-full flex-1 flex-col items-center text-center justify-center"
                >
                    <img
                        src={abc_2}
                        className="w-[60%] object-cover"
                        alt="placeholder"
                    />
                    <div
                        className="2xl:px-16 xl:px-12 lg:px-8 md:px-4 sm:px-2 
                        2xl:h-[10rem] flex flex-col justify-between pt-4"
                    >
                        <h1 className="font-montserrat text-center px-2 text-black font-semibold text-[16px]">
                            Contract Manufacturing
                        </h1>
                        <div className="pt-2">
                            <p className="font-roboto px-2 py-2 pb-4 text-center text-black text-[14px]">
                                Our contract manufacturing services provide
                                customized orders while ensuring high-quality
                                products.
                            </p>
                            <div className="flex justify-center px-2">
                                <button
                                    onClick={() => {
                                        navigate("/solutions");
                                        window.scrollTo(0, 0);
                                    }}
                                    className="border-2 border-gray-400 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 px-8 py-2 rounded-md transform transition-colors duration-200"
                                >
                                    LEARN MORE
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>

                <div className="border-l-2 border-gray-200 h-auto"></div>

                <ScrollAnimation
                    animateOut="fadeOutRight"
                    animateIn="fadeInRight"
                    duration={1}
                    className="flex h-full flex-1 flex-col items-center text-center justify-center"
                >
                    <img
                        src={abc_3}
                        className="w-[60%] object-cover"
                        alt="placeholder"
                    />
                    <div
                        className="2xl:px-16 xl:px-12 lg:px-8 md:px-4 sm:px-2 
                        2x:h-[10rem] flex flex-col justify-between pt-4"
                    >
                        <h1 className="font-montserrat text-center px-2 text-black font-semibold text-[16px]">
                            Private Label
                        </h1>
                        <div className="pt-2">
                            <p className="font-roboto px-2 py-2 pb-4 text-center text-black text-[14px]">
                                We provides private label services with fast
                                turnaround time and guidance from our team of
                                experts.
                            </p>
                            <div className="flex justify-center px-2">
                                <button
                                    onClick={() => {
                                        navigate("/solutions");
                                        window.scrollTo(0, 0);
                                    }}
                                    className="border-2 border-gray-400 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 px-8 py-2 rounded-md transform transition-colors duration-200"
                                >
                                    LEARN MORE
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
}
