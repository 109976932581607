import React from "react";
import contactHeader from "../assets/images/contact-header.png";
import locationIcon from "../assets/icons/location.png";
import phoneIcon from "../assets/icons/phone.png";
import emailIcon from "../assets/icons/email.png";
import formBG from "../assets/images/form-bg.png";

import facebookIcon from "../assets/icons/facebook.png";
import linkedinIcon from "../assets/icons/linkedin.png";
import globeIcon from "../assets/icons/globe.png";

import lineCurve from "../assets/images/line-curve.png";

import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";

export default function NinthPage() {
    const [formData, setFormData] = React.useState({
        sender_name: "",
        sender_address: "",
        sender_email: "",
        sender_company: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_EXPRESS_BACKEND_URL}/mails`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (response.ok) {
                alert("Message sent successfully!");
                setFormData({
                    sender_name: "",
                    sender_address: "",
                    sender_email: "",
                    sender_company: "",
                    message: "",
                });
            } else {
                alert("Failed to send message. Please try again.");
            }
        } catch (error) {
            console.error("Error sending message: ", error);
        }
    };

    const [googleMap, setGoogleMap] = React.useState(
        // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.9349928274605!2d106.7047367!3d10.739493699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f994eb03551%3A0x32cef81515f791c3!2zNTc3IMSQLiBOZ3V54buFbiBUaOG7iyBUaOG6rXAsIEtodSDEkcO0IHRo4buLIEhpbSBMYW0sIFF14bqtbiA3LCBI4buTIENow60gTWluaCA3MjkxNQ!5e0!3m2!1svi!2s!4v1721638981097!5m2!1svi!2s"
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyCVgO8KzHQ8iKcfqXgrMnUIGlD-piWiPpo&q=Lo+B31-1,Duong+Doc+2,Khu+cong+nghiep+Phu+An+Thanh,Xa+An+Thanh,Huyen+Ben+Luc,Tinh+Long+An,Viet+Nam&zoom=15&language=vi"
    );

    const handleLocationClicked = (location) => {
        switch (location) {
            case "head-office":
                setGoogleMap(
                    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.9349928274605!2d106.7047367!3d10.739493699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f994eb03551%3A0x32cef81515f791c3!2zNTc3IMSQLiBOZ3V54buFbiBUaOG7iyBUaOG6rXAsIEtodSDEkcO0IHRo4buLIEhpbSBMYW0sIFF14bqtbiA3LCBI4buTIENow60gTWluaCA3MjkxNQ!5e0!3m2!1svi!2s!4v1721638981097!5m2!1svi!2s"
                );
                setIsSection1Active(true);
                setIsSection2Active(false);
                setIsSection3Active(false);
                break;
            case "factory-1":
                setGoogleMap(
                    "https://www.google.com/maps/embed/v1/place?key=AIzaSyCVgO8KzHQ8iKcfqXgrMnUIGlD-piWiPpo&q=Lo+B31-1,Duong+Doc+2,Khu+cong+nghiep+Phu+An+Thanh,Xa+An+Thanh,Huyen+Ben+Luc,Tinh+Long+An,Viet+Nam&zoom=15&language=vi"
                );
                setIsSection1Active(false);
                setIsSection2Active(true);
                setIsSection3Active(false);
                break;
            case "factory-2":
                setGoogleMap(
                    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.8514593869363!2d107.29985887457194!3d10.512363464009876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175a0055508d3a3%3A0x4642f80a0c9eaf1a!2zS2h1IGPDtG5nIG5naGnhu4dwIMSQ4bqldCDEkOG7jyAx!5e0!3m2!1svi!2s!4v1721640754644!5m2!1svi!2s"
                );
                setIsSection1Active(false);
                setIsSection2Active(false);
                setIsSection3Active(true);
                break;
            default:
                break;
        }
    };

    const [isSection1Active, setIsSection1Active] = React.useState(false);
    const [isSection2Active, setIsSection2Active] = React.useState(false);
    const [isSection3Active, setIsSection3Active] = React.useState(false);

    const [isSmall, setIsSmall] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth <= 1100);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="space-y-8 mb-12">
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820726/nutramed/contact-header_aprfeo.png"
                    className="w-full"
                    alt="Contact Us Header"
                />
                <h1
                    className="absolute text-white font-montserrat font-bold
                    2xl:text-[42px] xl:text-[36px] lg:text-[32px] md:text-[28px] text-[24px]
                    2xl:top-[40%] 2xl:left-48 xl:top-[30%] xl:left-24 lg:top-[30%] lg:left-16 md:top-[25%] md:left-12 sm:top-[25%] sm:left-8 top-[25%] left-6"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                >
                    CONTACT US
                </h1>
            </div>
            <div className="flex py-12 flex-col">
                <h1 className="text-[24px] font-montserrat font-bold text-center">
                    NUTRAMED CORPORATION
                </h1>
                <h2 className="text-[16px] font-montserrat font-semibold text-center">
                    BRINGING SCIENCE TO LIFE
                </h2>
                <p className="text-[14px] text-gray-500 mt-2 font-roboto text-center">
                    Contact Nutramed for business inquiries, orders, or
                    feedback.
                </p>
            </div>
            <img src={lineCurve} className="w-full px-12" alt="Line Curve" />
            <ScrollAnimation
                animateOnce
                animateIn="fadeInUp"
                className={`flex ${
                    isSmall ? "flex-col" : "flex-row"
                } pt-32 pb-12 items-center justify-between
                2xl:pr-48 xl:pr-32 lg:px-24 md:px-16 sm:px-8 px-4`}
            >
                <div className="flex relative w-1/2">
                    <img
                        src={formBG}
                        className="w-full"
                        alt="form-bg"
                        style={{ transform: "scale(1.1)" }}
                    />
                    <h1 className="text-[24px] w-full absolute top-[40%] left-[62.5%] transform -translate-x-1/2 font-montserrat font-bold text-center my-8">
                        KEEP IN <span className="text-[#2E6D70]">TOUCH</span>{" "}
                    </h1>
                    <p className="absolute w-[13rem] font-roboto text-center top-[55%] left-[62.5%] transform -translate-x-1/2 text-black text-[14px]">
                        At Nutramed, customer satisfaction is our priority.
                        <span>
                            {" "}
                            Let us know how we can complement your experience.
                        </span>
                    </p>
                </div>
                <div
                    className="w-full bg-white font-roboto
                2xl:pl-28"
                >
                    <div className="space-y-6">
                        <div>
                            <input
                                type="text"
                                id="sender_name"
                                name="sender_name"
                                value={formData.sender_name}
                                onChange={handleChange}
                                className="mt-1 py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#2E6D70] sm:text-sm transform transition duration-500 ease-in-out"
                                placeholder="Name"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                id="sender_address"
                                name="sender_address"
                                value={formData.sender_address}
                                onChange={handleChange}
                                className="mt-1 py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#2E6D70] sm:text-sm transform transition duration-500 ease-in-out"
                                placeholder="Address"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                id="sender_email"
                                name="sender_email"
                                value={formData.sender_email}
                                onChange={handleChange}
                                className="mt-1 py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#2E6D70] sm:text-sm transform transition duration-500 ease-in-out"
                                placeholder="Email"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                id="sender_company"
                                name="sender_company"
                                value={formData.sender_company}
                                onChange={handleChange}
                                className="mt-1 py-2 w-full border-0 border-b-2 border-b-gray-400 focus:outline-none focus:border-b-[#2E6D70] sm:text-sm transform transition duration-500 ease-in-out"
                                placeholder="Your Company"
                                required
                            />
                        </div>
                        <div>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows={1}
                                className="mt-8 italic py-2 w-full border-0 border-b-2 border-b-[#2E6D70] placeholder:text-[#2E6D70] focus:outline-none focus:border-b-black sm:text-sm transform transition duration-500 ease-in-out"
                                placeholder="How can we support you?"
                                required
                            ></textarea>
                        </div>
                        <div className="flex w-full pt-8 justify-end">
                            <button
                                className="border-2 border-gray-400 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-semibold text-[15px] text-gray-500 px-8 py-2 rounded-md transform transition-colors duration-200"
                                onClick={handleSubmit}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeInUp"
                className="
                2xl:px-48 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="w-full h-1 bg-[#2E6D70]"></div>
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeInUp"
                className="2xl:px-48 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4    "
            >
                <div
                    className={`flex ${
                        isSmall ? "flex-col space-y-8" : "flex-row"
                    } items-center justify-between`}
                >
                    <div className="font-montserrat bg-[#4d4d4d] px-4 py-4 text-white text-[24px]">
                        CONTACT INFORMATION
                    </div>
                    <div className="flex space-x-12">
                        <div className="flex items-center space-x-2">
                            <img
                                src={phoneIcon}
                                className="w-10 h-10"
                                alt="phone-icon"
                            />
                            <div className="font-montserrat text-[#2E6D70] font-semibold">
                                PHONE NUMBER
                                <div className="flex font-normal space-x-4">
                                    <p className="text-[14px] px-4 py-1 text-white bg-[#2E6D70]">
                                        (+1) 857 701 8236
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <img
                                src={emailIcon}
                                className="w-12 h-8"
                                alt="email-icon"
                            />
                            <div className="font-montserrat text-[#2E6D70] font-semibold">
                                EMAIL
                                <div className="flex font-normal space-x-4">
                                    <p className="text-[14px] px-4 py-1 text-white bg-[#2E6D70]">
                                        info@nutramed.us
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeInUp"
                className="
                2xl:px-48 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="w-full h-[1px] bg-black"></div>
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeInUp"
                // className={`flex ${
                //     isSmall ? "flex-col space-y-8" : "flex-row"
                // } items-center space-x-4 justify-between pb-32
                // 2xl:px-48 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4`}
            >
                <div className="text-sm space-y-0">
                    {/* <div
                        className={`flex space-x-2 flex-row py-4 pr-4 hover:bg-gray-100 hover:cursor-pointer transform transition-colors duration-300 ${
                            isSection1Active ? `bg-gray-200` : ""
                        }`}
                        onClick={() => handleLocationClicked("head-office")}
                    >
                        <img
                            src={locationIcon}
                            className="w-[18px] h-[28px]"
                            alt="location-icon"
                        />
                        <div className="flex flex-col">
                            <h1 className="font-bold text-[16px] font-roboto">
                                Head Office
                            </h1>
                            <p className="text-[14px] flex flex-col font-roboto">
                                Building 577 Nguyen Thi Thap,
                                <span>
                                    Tan Phong Ward, District 7, Ho Chi Minh
                                </span>
                            </p>
                        </div>
                    </div> */}
                    {/* <div className="w-full h-[1px] bg-black"></div>
                    <div
                        className={`flex space-x-2 flex-row py-4 px-4 hover:bg-gray-100 hover:cursor-pointer transform transition-colors duration-300 ${
                            isSection2Active ? `bg-gray-200` : ""
                        }`}
                        onClick={() => handleLocationClicked("factory-1")}
                    >
                        <img
                            src={locationIcon}
                            className="w-[18px] h-[28px]"
                            alt="location-icon"
                        />
                        <div className="flex flex-col">
                            <h1 className="font-bold text-[16px] font-roboto">
                                Factory 1
                            </h1>
                            <p className="text-[14px] flex flex-col font-roboto">
                                Lot B31-1, Doc 2 Rd,
                                <span>
                                    Phu An Thanh Industrial Zone, An Thanh
                                    Commune, Ben Luc Dist, Long An Province
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        className={`flex space-x-2 flex-row py-4 px-4 hover:bg-gray-100 hover:cursor-pointer transform transition-colors duration-300 ${
                            isSection3Active ? `bg-gray-200` : ""
                        }`}
                        onClick={() => handleLocationClicked("factory-2")}
                    >
                        <img
                            src={locationIcon}
                            className="w-[18px] h-[28px]"
                            alt="location-icon"
                        />
                        <div className="flex flex-col">
                            <h1 className="font-bold text-[16px] font-roboto">
                                Factory 2
                            </h1>
                            <p className="text-[14px] flex flex-col font-roboto">
                                Lot 17, N11 Rd,
                                <span>
                                    Dat Do 1 Industrial Zone, Phuoc Long Tho
                                    Commune, Dat Do Dist, Ba Ria - Vung Tau
                                    Province
                                </span>
                            </p>
                        </div>
                    </div> */}
                </div>
                {/* <div className="">
                    <iframe
                        src={googleMap}
                        width="600"
                        height="300"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div> */}
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeIn"
                className="pt-24
                2xl:px-48 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="">
                    <div className="flex items-center justify-center mb-8 relative">
                        <div className="absolute inset-x-0 top-[85%] border-t border-black"></div>
                        <div className="relative font-montserrat flex flex-col text-center [16px] px-4 bg-white">
                            FROM
                            <span className="text-[24px] font-bold">
                                NUTRAMED
                            </span>
                        </div>
                    </div>
                    <div className="py-4">
                        <p
                            className="text-gray-500 flex flex-col text-[16px] font-roboto text-center
                        2xl:px-56 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4"
                        >
                            Our customers are at the heart of everything we do.
                            Ceaselessly maintaining an unwavering focus on
                            product excellence through persistent research and
                            development efforts, Nutramed is dedicated to
                            bringing you the best solutions for your growing
                            business.
                        </p>
                    </div>
                </div>
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeIn"
                className="flex flex-col items-center pb-20 space-y-6
                2xl:px-48 xl:px-32 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <p className="text-black flex flex-col text-[14px] font-roboto text-center mb-4">
                    Contact us directly via
                </p>
                <div className="flex justify-center space-x-12">
                    <img src={globeIcon} className="w-6 h-6" alt="globe-icon" />
                    <img
                        src={facebookIcon}
                        className="w-6 h-6"
                        alt="facebook-icon"
                    />
                    <img
                        src={linkedinIcon}
                        className="w-6 h-6 hover:cursor-pointer"
                        alt="linkedin-icon"
                        onClick={() =>
                            window.open(
                                "https://www.linkedin.com/company/nutramed-science-corporation/mycompany/",
                                "_blank"
                            )
                        }
                    />
                </div>
            </ScrollAnimation>
        </div>
    );
}
