import React from "react";
import xyz_02 from "../assets/images/xyz-01.png";
import aaa_1 from "../assets/images/aaa-01.png";
import aaa_2 from "../assets/images/aaa-02.png";
import { Link, useNavigate } from "react-router-dom";

import "animate.css/animate.compat.css";

import ScrollAnimation from "react-animate-on-scroll";

import office1 from "../assets/images/office.jpg";

export default function FifthPage() {
    const [isSmall, setIsSmall] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth <= 840);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const navigate = useNavigate();
    return (
        <div
            animateOnce
            animateIn="fadeInUp"
            className="flex flex-col pt-36 pb-64 bg-white h-full"
        >
            <div
                className="flex flex-col pb-8 
            2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8"
            >
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInUp"
                    className={`flex ${
                        isSmall ? "flex-col" : ""
                    } justify-start items-center`}
                >
                    <div className="w-1/3 flex justify-start items-end">
                        <h1 className="text-[42px] font-medium font-montserrat">
                            CSR
                        </h1>
                        <h2 className="text-[24px] pb-2 font-montserrat">
                            TO COMMUNITY
                        </h2>
                    </div>
                    <p className="w-2/3 text-[14px] text-justify text-black font-roboto">
                        Nutramed aims to promote a positive impact on society
                        and the environment through responsible business
                        practices, community engagement, and sustainable
                        production processes.
                    </p>
                </ScrollAnimation>
            </div>
            <div className="relative -mt-4">
                <img
                    className="object-cover h-full w-full"
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820927/nutramed/xyz-01_v11ksf.png"
                    alt="csr"
                />
                <div className="flex w-1/2 flex-row space-x-8 justify-end absolute top-16 
                2xl:right-48 xl:right-32 lg:right-24 md:right-16 sm:right-8 right-4">
                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInRight"
                        duration={1.25}
                        className="flex ml-8 rounded-br-2xl w-1/2 bg-white flex-col justify-between hover:shadow-xl transform duration-300 pb-4"
                    >
                        <div className="h-1/3">
                            <img
                                className="object-cover"
                                src="https://images.pexels.com/photos/998499/pexels-photo-998499.jpeg?auto=compress&cs=tinysrgb&h=627&fit=crop&w=1200"
                                alt="place-holder"
                            />
                            <div className="space-y-1 px-6 pt-4">
                                <p className="font-montserrat text-[10px]">
                                    Sustainability
                                </p>
                                <h1 className="text-[14px] font-montserrat font-bold">
                                    NUTRAMED’S ECO-FRIENDLY GLASS ALTERNATIVES
                                </h1>
                                <p className="font-montserrat text-[10px]">
                                    July 24, 2024 | 06:15 am GMT+7
                                </p>
                            </div>
                        </div>
                        <div className="flex pt-4 pl-6 justify-start w-full">
                            <button
                                onClick={() => {
                                    navigate("/sample-details");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:text-[#2E6D70] border-2 border-gray-300 px-2 py-1 italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                            >
                                Read More
                            </button>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateOnce
                        animateIn="fadeInRight"
                        duration={1.5}
                        className="flex rounded-br-2xl w-1/2 bg-white flex-col justify-between hover:shadow-xl transform duration-300 pb-4"
                    >
                        <div className="">
                            <img
                                className="object-cover"
                                src={office1}
                                alt="place-holder"
                            />
                            <div className="space-y-1 px-6 pt-4">
                                <p className="font-montserrat text-[10px]">
                                    Sustainability
                                </p>
                                <h1 className="text-[14px] font-montserrat font-bold">
                                    WOMEN EMPLOYEE AT NUTRAMED
                                </h1>
                                <p className="font-montserrat text-[10px]">
                                    July 24, 2024 | 06:15 am GMT+7
                                </p>
                            </div>
                        </div>
                        <div className="flex pt-4 pl-6 justify-start w-full">
                            <button
                                onClick={() => {
                                    navigate("/sample-details");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:text-[#2E6D70] border-2 border-gray-300 px-2 py-1 italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                            >
                                Read More
                            </button>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    );
}
