import React from "react";

export default function FirstMobile() {
    return (
        <div className="text-black">
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820750/nutramed/homepage-mobile-1_ohwhae.png"
                    alt="homepage-mobile-1"
                    className="w-full"
                />
                <div className="absolute w-[14rem] space-y-4 text-white flex flex-col top-[24%] left-1/2 transform -translate-x-1/2 ">
                    <h1
                        className=" text-white flex flex-col text-center text-[36px] font-montserrat font-semibold"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    >
                        NUTRAMED{" "}
                        <span className="text-[20px]">CORPORATION</span>
                    </h1>
                    <h2 className="text-center w-full text-[13px] font-montserrat font-semibold">
                        BRINGING SCIENCE TO LIFE
                    </h2>
                </div>
            </div>
            <div className="pt-24 px-8">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820897/nutramed/ntm-3-logos_pywqjr.png"
                    alt="homepage-mobile-2"
                    className="w-full"
                />
            </div>
        </div>
    );
}
