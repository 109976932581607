import React from 'react'
import newsDetailsHeader1 from '../assets/images/news-sample-header-1.png'
import newsDetailsHeader2 from '../assets/images/news-sample-header-2.png'
import rightIcon from '../assets/icons/right.png'

export default function NewsDetailsMobile() {
    return (
        <div className='pb-12 text-justify'>
            <img src={newsDetailsHeader1} alt='news-details-header' className='w-full object-cover' />
            <div className='space-y-4'>
                <div className='flex items-center pl-8 py-8 space-x-2'>
                    <p className='font-montserrat text-black text-[13px]'>Newsroom</p>
                    <img src={rightIcon} alt='fast-forward-icon' className='w-2.5 h-2.5 inline-block' />
                    <p className='font-montserrat text-black text-[13px]'>Corporate news</p>
                </div>
                <div className='space-y-2'>
                    <h1 className='text-[16px] px-4 text-start font-montserrat font-extrabold'>
                        NUTRAMED: FROM HERBAL INSPIRATION TO A
                        MULTINATIONAL PHARMACEUTICAL CORPORATION
                    </h1>
                    <p className='px-4 font-montserrat text-black text-[13px]'>July 24, 2024 | 06:15 am GMT+7</p>
                </div>
                <p className='px-4 font-montserrat text-black text-[13px]'>
                    Nutramed Science Corporation, a leading pharmaceutical company with roots in Vietnam's rich herbal heritage, has emerged as a global force in healthcare. With a
                    steadfast commitment to quality, innovation, and community well-being, Nutramed has expanded its reach while staying true to company’s origins.
                </p>
                <div className='flex justify-center'>
                    <img src={newsDetailsHeader2} alt='news-details-header' className='px-4 object-cover' />
                </div>
                <p className='px-4 font-montserrat font-semibold text-black text-[13px]'>
                    A Journey Begins Inspired by Nature
                </p>
                <p className='px-4 font-montserrat text-black text-[13px]'>
                    Nutramed was founded with a commitment to excellence, inspired by Vietnam's abundant natural medicinal resources and the rich traditions of traditional medicine
                    from both Vietnam and China. For thousands of years, these traditions have developed extensive systems using plants to treat various illnesses. Driven by a desire to offer
                    high-quality, safe, and effective products, Nutramed combines the art of traditional practices with modern scientific methods to create scientifically proven health
                    products.
                </p>
                <p className='px-4 font-montserrat text-black text-[13px]'>
                    After over six years of growth, Nutramed Corporation has achieved significant success, establishing itself as one of the most reputable pharmaceutical companies in
                    Vietnam and the Southeast Asia region. The company's dedication to research and development, along with its team of highly qualified and experienced experts in
                    medicine, pharmacy, chemistry, biology, and more, has positively impacted thousands of lives throughout Vietnam.
                </p>
                <p className='px-4 font-montserrat text-black text-[13px]'>
                    With two state-of-the-art GMP-certified factories and a wide-reaching distribution network, Nutramed is proud to provide high-quality, natural products to customers
                    seeking better health. While our journey began with a humble passion for natural healthcare products, our expansion into new countries and continents will help us
                    establish a legacy of trust and innovation.
                </p>
                <p className='px-4 font-montserrat text-black text-[13px]'>
                    We are more than just a company; we are pioneers in the field of modern herbal medicine.
                </p>
            </div>
        </div>
    )
}
