import React from "react";
import lineCurve from "../assets/images/line-curve.png";

export default function SixthMobile() {
    return (
        <div className="">
            <div className="relative bg-white font-montserrat">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820747/nutramed/history-mobile-1_xmvzy4.png"
                    alt="background"
                    className=""
                />
                <div className="absolute items-start text-white space-y-2 flex flex-col top-1/3 left-6">
                    <h1
                        className=" text-white text-4xl font-montserrat font-bold"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    >
                        ABOUT US
                    </h1>
                </div>
            </div>
            <div className="text-center w-full pt-8 flex self-center flex-col items-center font-roboto justify-center">
                <div className="flex px-8 space-y-2 my-4 flex-col">
                    <h1 className="text-2xl font-montserrat font-bold text-center mt-8">
                        NUTRAMED CORPORATION
                    </h1>
                    <h2 className="text-lg font-montserrat font-semibold text-center">
                        BRINGING SCIENCE TO LIFE
                    </h2>
                    <p className="text-sm text-gray-500 font-roboto font-normal text-center">
                        We are proud to be a pioneer in the fields of research,
                        production, and distribution of pharmaceuticals, health
                        supplements, cosmetics, and medical equipment. We
                        continually strive to maintain an unwavering reputation
                        for the delivery of exceptional products, services, and
                        solutions where quality and dependability are of the
                        utmost importance.{" "}
                    </p>
                </div>
            </div>
            <div className="px-8 mt-12 mb-20">
                <img src={lineCurve} alt="line curve" className="w-full" />
            </div>
        </div>
    );
}
