import React from "react";
import SixthBackground_01 from "../assets/images/sixth-bg-1.png";
import NavBar from "../components/NavBar";

export default function SixthPage() {
    return (
        <div className="">
            <div className="relative bg-white font-montserrat">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820911/nutramed/sixth-bg-1_vnzi7m.png"
                    alt="background"
                    className=""
                />
                <div
                    className="absolute items-start text-white space-y-2 flex flex-col 
                    2xl:top-[40%] 2xl:left-48 xl:top-[30%] xl:left-24 lg:top-[30%] lg:left-16 md:top-[25%] md:left-12 sm:top-[25%] sm:left-8 top-[25%] left-6"
                >
                    <h1
                        className=" text-white font-montserrat font-bold
                        2xl:text-[42px] xl:text-[36px] lg:text-[32px] md:text-[28px] text-[24px]"
                        style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                    >
                        ABOUT US
                    </h1>
                </div>
            </div>
            <div className="text-center w-full flex self-center flex-col py-8 bottom-20 items-center font-roboto justify-center">
                <div
                    className="flex py-4 flex-col
                2xl:px-20 xl:px-16 lg:px-12 md:px-8 sm:px-4 px-2"
                >
                    <h1 className="text-[24px] font-montserrat font-bold text-center mt-8">
                        NUTRAMED CORPORATION
                    </h1>
                    <h2 className="text-[16px] font-montserrat font-semibold text-center">
                        BRINGING SCIENCE TO LIFE
                    </h2>
                    <div>
                        <p
                            className="text-[14px] text-gray-500 font-roboto font-normal text-center mt-4
                        2xl:px-32 xl:px-24 lg:px-16 md:px-8 sm:px-4 px-2"
                        >
                            Being pioneer in the fields of research, production,
                            and distribution of pharmaceuticals, health
                            supplements, cosmetics, and medical equipment,{" "}
                        </p>
                        <p
                            className="text-[14px] text-gray-500 font-roboto font-normal text-center
                        2xl:px-32 xl:px-24 lg:px-16 md:px-8 sm:px-4 px-2"
                        >
                            we strive to maintain an unwavering reputation for
                            the delivery of exceptional products, services, and
                            solutions.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
