import React from "react";

import abc_1 from "../assets/images/csr-1.png";
import abc_2 from "../assets/images/solution2.png";
import abc_3 from "../assets/images/csr-3.png";

import lineCurve from "../assets/images/line-curve.png";
import SDside from "../assets/images/SD-side.jpg";

import { Link } from "react-router-dom";
import office1 from "../assets/images/office.jpg";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function TwelfthPage() {
    return (
        <div className="">
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820732/nutramed/csr-header-1_nfjlaj.png"
                    className="w-full"
                    alt="Solutions Header"
                />
                <h1
                    className="absolute text-white font-montserrat font-bold
                    2xl:text-[42px] xl:text-[36px] lg:text-[32px] md:text-[28px] text-[24px]
                    2xl:top-[40%] 2xl:left-48 xl:top-[30%] xl:left-24 lg:top-[30%] lg:left-16 md:top-[25%] md:left-12 sm:top-[25%] sm:left-8 top-[25%] left-6"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                >
                    CSR
                </h1>
                <h1
                    className="absolute  text-white font-montserrat font-bold
                    2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] text-[14px]
                    2xl:top-[55%] 2xl:left-48 xl:top-[45%] xl:left-24 lg:top-[45%] lg:left-16 md:top-[40%] md:left-12 sm:top-[45%] sm:left-8 top-[45%] left-6"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                >
                    TO COMMUNITY
                </h1>
            </div>
            <div className="flex my-8 mb-14 flex-col">
                <h1 className="text-[24px] font-montserrat font-bold text-center mt-8">
                    NUTRAMED CORPORATION
                </h1>
                <h2 className="text-[16px] font-montserrat font-semibold text-center">
                    BRINGING SCIENCE TO LIFE - BRING VALUES TO COMMUNITY
                </h2>
            </div>
            <img src={lineCurve} alt="line-curve" className="w-full px-12" />

            <ScrollAnimation
                animateOnce
                animateIn="fadeInLeft"
                className="flex mt-12 space-x-12
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="flex flex-col justify-center py-8 space-y-8 text-justify">
                    <h1 className="text-[24px] font-montserrat font-bold">
                        ENVIRONMENT ACTION
                    </h1>
                    <p className="font-roboto text-[13px] text-gray-500">
                        Nutramed recognizes the crucial importance of using the
                        least amount of plastic possible in our product
                        manufacturing and packaging. Replacing plastic bottles
                        with ecofriendly glass alternatives is one of our
                        unwavering efforts to create value and reduce
                        environmental impact. This transition not only helps
                        minimize plastic waste generation but also creates added
                        value for our products, aligning with Nutramed's
                        commitment to sustainability.
                    </p>
                </div>
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820730/nutramed/csr-1_rxje6i.png"
                    alt="abc-1"
                    className="w-[50%]"
                />
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeInRight"
                className="flex space-x-12
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820916/nutramed/solution2_qwqajv.png"
                    alt="abc-2"
                    className="w-[50%]"
                />
                <div className="flex flex-col justify-center py-8 space-y-8 text-justify">
                    <h1 className="text-[24px] font-montserrat font-bold">
                        GENDER EQUALITY
                    </h1>
                    <p className="font-roboto text-[13px] text-gray-500">
                        We continuously strive to foster a dynamic workplace
                        where each individual is valued. Beyond providing equal
                        opportunities for all employees, Nutramed actively
                        promotes gender equality and a culture of equity. By
                        empowering women to unleash their potential and
                        contribute to the company's overall growth, we believe
                        that our efforts can extend beyond the workplace and
                        contribute to broader gender equality within Vietnamese
                        society.
                    </p>
                </div>
            </ScrollAnimation>
            <ScrollAnimation
                animateOnce
                animateIn="fadeInLeft"
                className="flex space-x-12
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="flex flex-col justify-center py-8 space-y-8 text-justify">
                    <h1 className="text-[24px] font-montserrat font-bold">
                        WORKPLACE
                    </h1>
                    <p className="font-roboto text-[13px] text-gray-500">
                        At Nutramed, we cultivate a safe and healthy working
                        environment. The health and safety of our employees are
                        paramount to us at Nutramed. We understand our
                        responsibility to create a safe, healthy, and supportive
                        work environment, providing comprehensive welfare
                        benefits to our workforce. We remain committed to
                        fostering a workplace where employees feel empowered to
                        innovate and contribute while prioritizing their
                        well-being and respecting individual values.
                    </p>
                </div>
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820731/nutramed/csr-3_iu3ok9.png"
                    alt="abc-3"
                    className="w-[50%]"
                />
            </ScrollAnimation>
            <div
                className="flex space-x-12 justify-between pt-20 mt-20 pb-8
                2xl:px-48 xl:px-36 lg:px-24 md:px-16 sm:px-8 px-4"
            >
                <div className="space-y-4">
                    <p className="font-montserrat text-[24px] font-bold">
                        Commitment to
                    </p>
                    <h1 className="font-montserrat text-[42px] font-bold">
                        Sustainable Development
                    </h1>
                    <p className="font-roboto pb-8 text-[13px] text-gray-500">
                        As a leading pharmaceutical and nutraceutical
                        corporation, Nutramed pledges to continue pursuing
                        sustainable practices well into the future. The company
                        will invest in ecofriendly manufacturing technologies,
                        utilize recycled materials, and raise employee awareness
                        about the significance of environmental protection.
                        Nutramed will further engage in community support
                        initiatives, contributing to the development of a
                        sustainable society.
                    </p>
                    <div className="flex space-x-12">
                        <div className="flex w-1/2 flex-col rounded-br-2xl justify-between hover:shadow-xl transform duration-300 pb-4">
                            <div className="">
                                <img
                                    className="object-cover"
                                    src="https://images.pexels.com/photos/998499/pexels-photo-998499.jpeg?auto=compress&cs=tinysrgb&h=627&fit=crop&w=1200"
                                    alt="place-holder"
                                />
                                <div className="space-y-1 px-6 pt-4">
                                    <p className="font-montserrat text-[10px]">
                                        Sustainability
                                    </p>
                                    <h1 className="text-[13px] font-montserrat font-bold">
                                        NUTRAMED’S ECO-FRIENDLY GLASS
                                        ALTERNATIVES
                                    </h1>
                                    <p className="font-montserrat text-[10px]">
                                        July 24, 2024 | 06:15 am GMT+7
                                    </p>
                                </div>
                            </div>
                            <div className="flex pt-4 pl-6 justify-start w-full">
                                <Link
                                    to="/sample-details"
                                    className="hover:text-[#2E6D70] border-2 border-gray-300 px-2 py-1 italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                        <div className="flex w-1/2 flex-col rounded-br-2xl justify-between hover:shadow-xl transform duration-300 pb-4">
                            <div className="">
                                <img
                                    className="object-cover"
                                    src={office1}
                                    alt="place-holder"
                                />
                                <div className="space-y-1 px-6 pt-4">
                                    <p className="font-montserrat text-[10px]">
                                        Sustainability
                                    </p>
                                    <h1 className="text-[13px] font-montserrat font-bold">
                                        WOMEN EMPLOYEE AT NUTRAMED
                                    </h1>
                                    <p className="font-montserrat text-[10px]">
                                        July 24, 2024 | 06:15 am GMT+7
                                    </p>
                                </div>
                            </div>
                            <div className="flex pt-4 pl-6 justify-start w-full">
                                <Link
                                    to="/sample-details"
                                    className="hover:text-[#2E6D70] border-2 border-gray-300 px-2 py-1 italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={SDside} alt="factory-side" className="w-[40%]" />
            </div>
            <div className="pb-20 pt-12 mt-12">
                <p className="text-center font-montserrat text-[16px] font-semibold">
                    Nutramed’s Endeavor
                </p>
                <h1 className="text-center font-montserrat text-[24px] font-bold">
                    TO GLOBAL ENGAGEMENT
                </h1>
                <div
                    className="text-center items-center flex flex-col justify-center pt-4 space-y-4
                2xl:px-20 xl:px-16 lg:px-12 md:px-8 sm:px-4 px-4"
                >
                    <div className="flex flex-col items-center justify-center space-y-2 italic w-[50%]">
                        <p className="text-[13px] flex flex-col text-center text-gray-800 font-roboto font-normal">
                            By fostering a culture of collaboration and shared
                            responsibility,
                            <span>
                                Nutramed aims to be a driving force in building
                                a healthier planet for generations to come.
                            </span>
                        </p>
                        <p className="text-[13px] text-center text-gray-800 pb-4 font-roboto font-normal">
                            We hereby invite our stakeholders—employees,
                            customers, suppliers, and community partners—to join
                            us in this journey of creating a bright future for
                            the planet. Together, we can follow sustainable
                            practices across the supply chain.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
