import React from "react";
import ProductCard from "../components/ProductCard";
import productsHeader from "../assets/images/products-header.png";
import lineCurve from "../assets/images/line-curve.png";
import productBackGround_1 from "../assets/images/products-background-1.jpg";
import { Link, useNavigate } from "react-router-dom";

export default function EleventhMobile() {
    const navigate = useNavigate();
    return (
        <div>
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722833234/nutramed/products-header-mobile_o9k87f.png"
                    className="w-full"
                    alt="Solutions Header"
                />
                <h1
                    className="absolute top-[30%] left-5  text-white text-[30px] font-montserrat font-bold"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                >
                    PRODUCTS
                </h1>
            </div>
            <div className="flex my-8 flex-col">
                <div className="flex px-8 flex-col">
                    <h1 className="text-2xl font-montserrat font-bold text-center mt-4">
                        NUTRAMED CORPORATION
                    </h1>
                    <h2 className="text-lg font-montserrat font-semibold text-center">
                        BRINGING SCIENCE TO LIFE
                    </h2>
                    <p className="text-sm text-gray-500 font-roboto font-normal text-center">
                        We produce modern medicines, herbal supplements,
                        traditional medicines, and health foods in all dosage
                        forms
                    </p>
                </div>
            </div>
            <img src={lineCurve} className="w-full px-12" alt="Line Curve" />
            <div className="relative mt-4">
                <img
                    src={productBackGround_1}
                    className="w-full"
                    alt="Product Background"
                />
                <div className="absolute top-4 flex mt-6  space-y-4 flex-col px-8">
                    <div className="flex space-x-4 flex-row">
                        <div className="flex-1 px-2 text-center hover:bg-[#2E6D70] hover:cursor-default hover:text-white transform transition-colors duration-300 rounded-t-2xl rounded-bl-2xl items-center justify-center h-[6rem] bg-white shadow-lg flex flex-col">
                            <h1 className="text-[15px] font-montserrat font-bold">
                                Pharmaceuticals
                            </h1>
                            <div className="flex">
                                <button
                                    onClick={() => {
                                        navigate("/categories");
                                        window.scrollTo(0, 0);
                                    }}
                                    className="italic bg-transparent font-roboto font-medium text-[12px] px-4 py-2 rounded-md"
                                >
                                    VIEW PRODUCTS
                                </button>
                            </div>
                        </div>
                        <div className="flex-1 px-2 text-center bg-opacity-90 hover:bg-[#2E6D70] hover:border-[#2E6D70] hover:cursor-default hover:text-white transform transition-colors duration-300 rounded-t-2xl rounded-br-2xl items-center justify-center h-[6rem] bg-white shadow-lg flex flex-col">
                            <h1 className="text-[15px] font-montserrat font-bold">
                                Cosmetics
                            </h1>
                            <div className="flex">
                                <button
                                    onClick={() => {
                                       navigate("/categories");
                                       window.scrollTo(0, 0);
                                    }}
                                    className="italic bg-transparent font-roboto font-medium text-[12px] px-4 py-2 rounded-md"
                                >
                                    VIEW PRODUCTS
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex space-x-4 flex-row">
                        <div className="flex-1 px-2 text-center bg-opacity-90 hover:bg-[#2E6D70] hover:border-[#2E6D70] hover:cursor-default hover:text-white transform transition-colors duration-300 rounded-b-2xl rounded-tl-2xl items-center justify-center h-[6rem] bg-white shadow-lg flex flex-col">
                            <h1 className="text-[15px] font-montserrat font-bold">
                                Medical Equipment
                            </h1>
                            <div className="flex">
                                <button
                                    onClick={() => {
                                       navigate("/categories");
                                       window.scrollTo(0, 0);
                                    }}
                                    className="italic bg-transparent font-roboto font-medium text-[12px] px-4 py-2 rounded-md"
                                >
                                    VIEW PRODUCTS
                                </button>
                            </div>
                        </div>
                        <div className="flex-1 px-2 text-center bg-opacity-90 hover:bg-[#2E6D70] hover:border-[#2E6D70] hover:cursor-default hover:text-white transform transition-colors duration-300 rounded-b-2xl rounded-tr-2xl items-center justify-center h-[6rem] bg-white shadow-lg flex flex-col">
                            <h1 className="text-[15px] font-montserrat font-bold">
                                Health Supplements
                            </h1>
                            <div className="flex">
                                <button
                                    onClick={() => {
                                        navigate("/categories");
                                        window.scrollTo(0, 0);
                                    }}
                                    className="italic bg-transparent font-roboto font-medium text-[12px] px-4 py-2 rounded-md"
                                >
                                    VIEW PRODUCTS
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
