import React from "react";
import cers_1 from "../assets/images/cers-2.png";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export default function EighthMobile() {
    return (
        <div>
            <div className="h-[42rem]">
                <div className="relative">
                    <img
                        src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820749/nutramed/history-mobile-3_y4oqr6.png"
                        alt="historyMobile3"
                        className="w-full h-auto object-cover"
                    />
                    <div className="absolute pt-3 bg-gray-200 bg-opacity-20 w-full text-center items-center text-white space-y-2 flex flex-col left-1/2 transform -translate-x-1/2 top-[80%]">
                        <h1
                            className=" text-white text-2xl font-montserrat font-bold"
                            style={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                            }}
                        >
                            CORE VALUES
                        </h1>
                        <ScrollAnimation
                            animateOnce
                            animateIn="fadeInUp"
                            className="flex shadow-xl text-start px-8 font-montserrat py-8 flex-col space-y-2"
                        >
                            <div>
                                <h1 className=" text-black text-[16px] font-bold">
                                    Innovation and Excellence
                                </h1>
                                <h2 className=" text-gray-500 font-roboto text-[13px]">
                                    Relentlessly improve and innovate in all
                                    aspects of our work.
                                </h2>
                            </div>
                            <div>
                                <h1 className=" text-black text-[16px] font-bold">
                                    Integrity and Responsibility
                                </h1>
                                <h2 className=" text-gray-500 font-roboto text-[13px]">
                                    Conduct business with the highest standards,
                                    fostering trust among all stakeholders.
                                </h2>
                            </div>
                            <div>
                                <h1 className=" text-black text-[16px] font-bold">
                                    Customer Focus
                                </h1>
                                <h2 className=" text-gray-500 font-roboto text-[13px]">
                                    Strive to exceed customer expectations in
                                    every interaction.
                                </h2>
                            </div>
                            <div>
                                <h1 className=" text-black text-[16px] font-bold">
                                    Collaboration
                                </h1>
                                <h2 className=" text-gray-500 font-roboto text-[13px]">
                                    Promote a diverse and inclusive workplace
                                    reflective of our global footprint.
                                </h2>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div className="flex mt-12 mb-8 flex-col">
                <div className="relative">
                    <img
                        src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820737/nutramed/e-2-mobile_eis69z.png"
                        alt="e-2"
                        className="object-cover opacity-70"
                    />
                    <div className="absolute flex flex-col w-full top-[5%]">
                        <h1 className="text-gray-800 px-8 text-2xl text-center font-semibold font-montserrat">
                            CERTIFICATES
                        </h1>
                        <p className="pb-8 pt-2 px-8 flex flex-col text-center text-sm text-gray-800 font-roboto">
                            We are proud to be a leading manufacturer
                            <span>who received the certification.</span>
                        </p>
                    </div>
                    <img
                        src={cers_1}
                        alt="cers-1"
                        className="w-full pt-4 pl-2"
                    />
                    <div className="flex px-4">
                        <div className="flex flex-1 text-center flex-col space-y-2 items-center">
                            <h1 className="text-black text-[13px] font-semibold">
                                GMP CERTIFIED
                            </h1>
                            <p className="text-gray-500 px-1 text-xs font-roboto">
                                Our facility follows strict GMP standards for
                                all production steps.
                            </p>
                        </div>
                        <div className="flex flex-1 text-center flex-col space-y-2 items-center">
                            <h1 className="text-black text-[13px] font-semibold">
                                NSF CERTIFIED
                            </h1>
                            <p className="text-gray-500 px-1 text-xs font-roboto">
                                Our products meet rigorous safety and quality
                                standards. This certification ensures consumer
                                safety.
                            </p>
                        </div>
                        <div className="flex flex-1 text-center flex-col space-y-2 items-center">
                            <h1 className="text-black text-[13px] font-semibold">
                                NPA CERTIFIED
                            </h1>
                            <p className="text-gray-500 px-1 text-xs font-roboto">
                                Our NPA-certified products are natural,
                                effective, and highquality.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820741/nutramed/e-3_jaiyp0.png"
                    alt="e-3"
                    className="w-full h-auto object-cover"
                />
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInUp"
                    className="flex flex-1 items-start mt-8 space-x-0 bg-gray-800"
                >
                    <h1 className="text-white py-2 text-end w-[35%] px-4 text-[16px] font-semibold font-montserrat">
                        SERVICE
                    </h1>
                    <p className="text-xs w-[65%] py-2 px-2 pl-4 bg-gray-900 text-start text-white font-roboto">
                        We provide contract manufacturing and private labeling
                        services for high-quality pharmaceuticals and
                        nutraceuticals.
                    </p>
                </ScrollAnimation>
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInUp"
                    className="flex flex-1 items-start space-x-0 bg-gray-800"
                >
                    <h1 className="text-white py-2 w-[35%] text-end px-4 text-[16px] font-semibold font-montserrat">
                        PRODUCTS
                    </h1>
                    <p className="text-xs w-[65%] py-2 px-2 pl-4 bg-gray-900 text-start text-white font-roboto">
                        Our wide range of products covers indications including
                        women’s health, men’s health, dermatology, urology,
                        vitamins, and more.
                    </p>
                </ScrollAnimation>
                <ScrollAnimation
                    animateOnce
                    animateIn="fadeInUp"
                    className="flex flex-1 items-start space-x-0 bg-gray-800"
                >
                    <h1 className="text-white py-2 text-end w-[35%] px-4 text-[16px] font-semibold font-montserrat">
                        CAPABILTIES
                    </h1>
                    <p className="text-xs w-[65%] py-2 px-2 pl-4 bg-gray-900 text-start text-white font-roboto">
                        Nutramed boasts 2 GMP-certified factories with
                        state-of-the-art laboratories, home to a hub of
                        multidisciplinary experts including scientists,
                        pharmacists, QA specialists, and more.
                    </p>
                </ScrollAnimation>
            </div>
        </div>
    );
}
