import React from "react";
import SCX_01 from "../assets/icons/SCX-01.png";
import SCX_02 from "../assets/icons/SCX-02.png";
import SCX_03 from "../assets/icons/SCX-03.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "animate.css/animate.compat.css";

export default function FourthMobile() {
    return (
        <div className="w-full">
            <div className="relative h-screen">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722823441/nutramed/xyz-02_favqdb.png"
                    alt="background"
                    className="object-cover h-full w-full"
                />
                <div className="absolute z-10 inset-1 px-2 flex flex-col items-center justify-center">
                    <div className="flex flex-col justify-center items-center space-y-4">
                        <h1
                            className="text-white font-montserrat pb-4 text-center text-3xl font-bold"
                            style={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                            }}
                        >
                            WHAT MAKES NUTRAMED DIFFERENT
                        </h1>
                        <div
                            animateOnce
                            animateIn="fadeInRight"
                            duration={1}
                            className="flex items-center justify-between bg-gray-200 bg-opacity-90 rounded-xl"
                        >
                            <img
                                src={SCX_01}
                                alt="Resource & Capabilities"
                                className="w-[150px] h-[150px]"
                            />
                            <div className="bg-[#2E6D70] space-y-2 px-2 py-3 rounded-r-xl">
                                <h1 className="text-center font-montserrat text-white text-lg font-semibold">
                                    R&D Expertise
                                </h1>
                                <div className="border-b border-b-white w-full"></div>
                                <p className="text-end font-roboto text-white text-xs font-normal">
                                    Nutramed leverages cutting-edge R&D to
                                    develop advanced, effective healthcare
                                    products. We offer a comprehensive portfolio
                                    (pharmaceuticals, supplements, and health
                                    foods) to meet diverse market demands.
                                </p>
                            </div>
                        </div>
                        <div
                            animateOnce
                            animateIn="fadeInLeft"
                            duration={1.25}
                            className="flex items-center justify-between bg-gray-200 bg-opacity-90 rounded-xl"
                        >
                            <div className="bg-[#2E6D70] space-y-2 px-2 py-3 rounded-l-xl">
                                <h1 className="text-center font-montserrat text-white text-lg font-semibold">
                                    Innovative Formulations
                                </h1>
                                <div className="border-b border-b-white w-full"></div>
                                <p className="text-start font-roboto text-white text-xs font-normal">
                                    Our R&D office in Vancouver, Canada, closely
                                    collaborates with a manufacturing team in
                                    Vietnam with 2 decades of experience in the
                                    industry. This synergy allows us to develop
                                    innovative formulations that are both
                                    effective and safe.
                                </p>
                            </div>
                            <img
                                src={SCX_02}
                                alt="Resource & Capabilities"
                                className="w-[150px] h-[150px]"
                            />
                        </div>
                        <div
                            animateOnce
                            animateIn="fadeInRight"
                            duration={1.5}
                            className="flex items-center justify-between bg-gray-200 bg-opacity-90 rounded-xl"
                        >
                            <img
                                src={SCX_03}
                                alt="Resource & Capabilities"
                                className="w-[150px] h-[150px]"
                            />
                            <div className="bg-[#2E6D70] space-y-2 px-2 py-4 rounded-r-xl">
                                <h1 className="text-center font-montserrat text-white text-lg font-semibold">
                                    Global Network
                                </h1>
                                <div className="border-b border-b-white w-full"></div>
                                <p className="text-end font-roboto text-white text-xs font-normal">
                                    Nutramed has a global network of partners in
                                    the healthcare industry. We work with
                                    leading research institutions, universities,
                                    and manufacturers to develop high-quality
                                    products that meet international standards.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
