import React from "react";
import aaa_3 from "../assets/images/aaa-03.png";

import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";

export default function SecondPage() {
    const navigate = useNavigate();

    return (
        <div className="bg-white mt-20 mb-12 h-[46rem]">
            <div className="relative flex flex-col">
                <div className="relative z-10">
                    <img src={aaa_3} alt="background" className="" />
                    <div
                        className="absolute 
                        flex flex-col 
                        left-[1%] -top-[35%] 
                        sm:left-[2.55%] sm:-top-[25%] 
                        md:left-[5%] md:-top-[20%] 
                        lg:left-[7.5%] lg:-top-[15%] 
                        xl:left-[10%] xl:-top-[10%] 
                        2xl:left-[13%] 2xl:inset-0 
                        2xl:w-[40rem] xl:w-[35rem] lg:w-[30rem] md:w-[25rem] sm:w-[20rem] w-[15rem]"
                    >
                        <h1 className="flex mt-[50px] sm:mt-[65px] md:mt-[80px] lg:mt-[95px] xl:mt-[110px] 2xl:mt-[125px] font-montserrat text-white text-[14px] sm:text-[18px] md:text-[24px] lg:text-[30px] xl:text-[36px] 2xl:text-[42px] font-bold">
                            ABOUT NUTRAMED
                        </h1>
                        <p
                            className="flex mt-4 
                            2xl:w-[25rem] 
                            font-roboto text-white font-light
                            text-[12px] sm:text-[12px] md:text-[13px] lg:text-[14px] xl:text-[14px] 2xl:text-[14px]"
                            style={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                            }}
                        >
                            Nutramed Science Corporation (Nutramed) is a rapidly
                            expanding leader in the research, production, and
                            distribution of pharmaceuticals, nutraceuticals,
                            cosmetics, and medical equipment.
                        </p>
                        <div className="flex mt-4">
                            <button
                                onClick={() => {
                                    navigate("/about-us");
                                    window.scrollTo(0, 0);
                                }}
                                className="border-2 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-white px-8 py-2 rounded-md transform transition-colors duration-200"
                            >
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex absolute max-w-[96rem] -bottom-80 w-full z-0">
                    <div className="flex bg-[#F0F0F0] text-center space-y-4 h-[22rem] pt-4 justify-center w-full flex-col items-center">
                        <h1 className="font-montserrat text-black text-[24px] font-semibold">
                            Capabilities
                        </h1>
                        <h2
                            className="font-roboto text-center 
                        2xl:px-36 xl:px-24 lg:px-20 md:px-16 sm:px-12 px-8 
                        mt-2 mb-4 text-gray-500 text-[14px] font-normal"
                        >
                            Nutramed boasts comprehensive manufacturing and R&D
                            capabilities, supported by state-of-the-art
                            facilities and expert teams.
                        </h2>
                        <button
                            onClick={() => {
                                navigate("/solutions");
                                window.scrollTo(0, 0);
                            }}
                            className="border-2 border-gray-500 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-600 px-8 py-2 rounded-md transform transition-colors duration-200"
                        >
                            LEARN MORE
                        </button>
                    </div>
                    <div className="border-l-2 border-black h-auto"></div>
                    <div className="flex bg-[#e2e2e2] text-center space-y-4 h-[22rem] pt-4 justify-center w-full flex-col items-center">
                        <h1 className="font-montserrat text-black text-[24px] font-semibold">
                            Products
                        </h1>
                        <h2
                            className="font-roboto text-center 
                        2xl:px-36 xl:px-24 lg:px-20 md:px-16 sm:px-12 px-8
                        mt-2 mb-4 text-gray-500 text-[14px] font-normal"
                        >
                            Nutramed specializes in producing medicines, health
                            supplements, nutraceuticals, and cosmetics.
                        </h2>
                        <button
                            onClick={() => {
                                navigate("/products");
                                window.scrollTo(0, 0);
                            }}
                            className="border-2 border-gray-500 hover:text-[#2E6D70] italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-600 px-8 py-2 rounded-md transform transition-colors duration-200"
                        >
                            LEARN MORE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
