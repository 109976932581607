import React from "react";
import nutramedBackground_1 from "../assets/images/HPBG-01-01.png";
import nutramedLogos from "../assets/images/ntm-3-logos.png";

export default function FirstPage() {
    return (
        <div className="flex flex-col">
            <div className="relative bg-white font-montserrat">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820774/nutramed/HPBG-01-01_ihkq7x.png"
                    alt="background"
                    className=""
                />
                <div className="absolute inset-0 top-[15%] flex items-center justify-center">
                    <div className="flex flex-col items-center justify-center rounded-full 2xl:px-32 py-12">
                        <h1 className=" text-white text-[14px] sm:text-[18px] md:text-[24px] lg:text-[30px] xl:text-[36px] 2xl:text-[42px] font-semibold mb-2">
                            NUTRAMED CORPORATION
                        </h1>
                        <h2 className=" text-white text-[8px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] 2xl:text-[24px] font-medium">
                            BRINGING SCIENCE TO LIFE
                        </h2>
                    </div>
                </div>
            </div>
            <img
                src={nutramedLogos}
                alt="logos"
                className="flex self-center my-24 w-[95%] sm:w-[90%] md:w-[85%] lg:w-[80%] xl:w-[75%] 2xl:w-[70%]"
            />
        </div>
    );
}
