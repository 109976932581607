import React from "react";
import nutramedLogo from "../assets/images/nutramed_logo_01.png";
import searchIcon from "../assets/icons/search_icon.png";

import { Link, useNavigate } from "react-router-dom";

export default function NavBar() {
    const [showDropdown, setShowDropdown] = React.useState(false);
    const navigate = useNavigate();

    const navRef = React.useRef(null);

    const handleShowDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCloseDropdown = () => {
        setShowDropdown(false);
    };

    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 990);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 990);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleNavigation = (path) => {
        setShowDropdown(false);
        navigate(path);
        window.scrollTo(0, 0);
    };

    const handleLogoClick = () => {
        setShowDropdown(false);
        navigate("/");
        window.scrollTo(0, 0);
    };

    const handleAboutClick = () => {
        setShowDropdown(false);
        navigate("/about-us");
        window.scrollTo(0, 0);
    };

    const handleSolutionsClick = () => {
        setShowDropdown(false);
        navigate("/solutions");
        window.scrollTo(0, 0);
    };

    const handleProductsClick = () => {
        setShowDropdown(false);
        navigate("/products");
        window.scrollTo(0, 0);
    };

    const handleCSRClick = () => {
        setShowDropdown(false);
        navigate("/csr");
        window.scrollTo(0, 0);
    };

    const handleNewsroomClick = () => {
        setShowDropdown(false);
        navigate("/newsroom");
        window.scrollTo(0, 0);
    };

    const handleContactClick = () => {
        setShowDropdown(false);
        navigate("/contact-us");
        window.scrollTo(0, 0);
    };

    return (
        <nav className="flex flex-row justify-between pr-8 pl-8 lg:pr-4 pg:pl-4 xl:pr-8 xl:pl-16 2xl:pr-40 2xl:pl-48 sticky top-0 z-50 bg-black bg-opacity-20 backdrop-blur-sm shadow-xl text-white">
            <div
                onClick={handleLogoClick}
                className="flex justify-center items-center hover:cursor-pointer"
            >
                <img
                    src={nutramedLogo}
                    className="w-[133px] h-[100px]"
                    alt="Nutramed Corporation Logo"
                />
            </div>
            {isMobile ? (
                <div className="items-center flex justify-center h-full">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row font-medium justify-between font-montserrat">
                            <button
                                onClick={handleShowDropdown}
                                className="text-[16px] px-8 py-12 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200"
                            >
                                MENU
                            </button>
                        </div>
                    </div>
                    {showDropdown && (
                        <div
                            ref={navRef}
                            className="flex flex-col absolute top-28 left-0 w-full bg-white backdrop-blur-sm shadow-xl text-[#2E6D70]"
                        >
                            <button
                                onClick={handleAboutClick}
                                className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200 hover:text-white"
                            >
                                ABOUT
                            </button>
                            <button
                                onClick={handleSolutionsClick}
                                className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200 hover:text-white"
                            >
                                SOLUTIONS
                            </button>
                            <button
                                onClick={handleProductsClick}
                                className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200 hover:text-white"
                            >
                                PRODUCTS
                            </button>
                            <button
                                onClick={handleCSRClick}
                                className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200 hover:text-white"
                            >
                                CSR
                            </button>
                            <button
                                onClick={handleNewsroomClick}
                                className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200 hover:text-white"
                            >
                                NEWSROOM
                            </button>
                            <button
                                onClick={handleContactClick}
                                className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200 hover:text-white"
                            >
                                CONTACT
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row font-medium justify-between font-montserrat">
                        <button
                            onClick={() => handleNavigation("/about-us")}
                            className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200"
                        >
                            ABOUT
                        </button>
                        <button
                            onClick={() => handleNavigation("/solutions")}
                            className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200"
                        >
                            SOLUTIONS
                        </button>
                        <button
                            onClick={() => handleNavigation("/products")}
                            className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200"
                        >
                            PRODUCTS
                        </button>
                        <button
                            onClick={() => handleNavigation("/csr")}
                            className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200"
                        >
                            CSR
                        </button>
                        <button
                            onClick={() => handleNavigation("/newsroom")}
                            className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200"
                        >
                            NEWSROOM
                        </button>
                        <button
                            onClick={() => handleNavigation("/contact-us")}
                            className="flex-1 text-[16px] px-8 py-4 cursor-pointer hover:bg-[#2E6D70] transform transition-colors duration-200"
                        >
                            CONTACT
                        </button>
                    </div>
                </div>
            )}
        </nav>
    );
}
