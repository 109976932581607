import React from "react";
import homepageMobile4 from "../assets/images/homepage-mobile-4.png";
import aaa_1 from "../assets/images/aaa-01.png";
import aaa_2 from "../assets/images/aaa-02.png";
import office1 from "../assets/images/office.jpg";

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";

export default function FifthMobile() {
    const navigate = useNavigate();
    return (
        <div className="pb-12 h-[66rem]">
            <div className="flex items-center space-x-2 px-8 justify-between">
                <h1 className="text-4xl font-montserrat">CSR</h1>
                <h2 className="text-xl border-b-2 border-[#2E6D70] w-full text-start font-montserrat">
                    TO THE COMMUNITY
                </h2>
            </div>
            <p className=" text-black text-justify pb-4 px-8 text-xs font-montserrat py-1">
                Nutramed is committed to corporate social responsibility and
                sustainability. We aim to promote a positive impact on society
                and the environment through responsible business practices,
                community engagement, and sustainable production processes.
            </p>
            <div className="relative">
                <img
                    src="https://res.cloudinary.com/diutkhjd3/image/upload/v1722820773/nutramed/homepage-mobile-4_ceef4y.png"
                    alt="csr"
                    className="object-cover"
                />
                <div className="absolute flex flex-col items-center justify-center space-y-4 top-[45%]">
                    <div
                        animateOnce
                        animateIn="fadeInRight"
                        duration={1.25}
                        className="flex w-[80%] bg-white flex-col justify-between hover:shadow-xl transform duration-300 pb-4"
                    >
                        <div className="h-1/3">
                            <img
                                className="object-cover"
                                src="https://images.pexels.com/photos/998499/pexels-photo-998499.jpeg?auto=compress&cs=tinysrgb&h=627&fit=crop&w=1200"
                                alt="place-holder"
                            />
                            <div className="space-y-1 px-4 pt-4">
                                <p className="font-montserrat text-[10px]">
                                    Sustainability
                                </p>
                                <h1 className="text-[13px] font-montserrat font-bold">
                                    NUTRAMED’S ECO-FRIENDLY GLASS ALTERNATIVES
                                </h1>
                                <p className="font-montserrat text-[10px]">
                                    July 24, 2024 | 06:15 am GMT+7
                                </p>
                            </div>
                        </div>
                        <div className="flex pt-4 pl-4 justify-start w-full">
                            <button
                                onClick={() => {
                                    navigate("/sample-details");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:text-[#2E6D70] border-2 border-gray-300 px-2 py-1 italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                            >
                                Read More
                            </button>
                        </div>
                    </div>
                    <div
                        animateOnce
                        animateIn="fadeInRight"
                        duration={1.5}
                        className="flex w-[80%] bg-white flex-col justify-between hover:shadow-xl transform duration-300 pb-4"
                    >
                        <div className="h-1/3">
                            <img
                                className="object-cover"
                                src={office1}
                                alt="place-holder"
                            />
                            <div className="space-y-1 px-4 pt-4">
                                <p className="font-montserrat text-[10px]">
                                    Sustainability
                                </p>
                                <h1 className="text-[13px] font-montserrat font-bold">
                                    WOMEN EMPLOYEE AT NUTRAMED
                                </h1>
                                <p className="font-montserrat text-[10px]">
                                    July 24, 2024 | 06:15 am GMT+7
                                </p>
                            </div>
                        </div>
                        <div className="flex pt-4 pl-4 justify-start w-full">
                            <button
                                onClick={() => {
                                    navigate("/sample-details");
                                    window.scrollTo(0, 0);
                                }}
                                className="hover:text-[#2E6D70] border-2 border-gray-300 px-2 py-1 italic hover:border-[#2E6D70] bg-transparent font-roboto font-bold text-[12px] text-gray-500 rounded-md transform transition-colors duration-200"
                            >
                                Read More
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
