import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ProductMobileCard({ product }) {
    const navigate = useNavigate();
    return (
        <div
            onClick={() => {
                navigate(`/product/${product.id}`);
                window.scrollTo(0, 0);
            }}
            className="flex flex-col justify-between shadow-xl space-x-2"
        >
            <img
                src="https://via.placeholder.com/1600x900"
                alt={product.name}
                className="w-full object-cover"
            />
            <div className="flex flex-col justify-between">
                <div className="p-2">
                    <h1 className="text-[16px] pb-4 text-start font-bold">
                        {product.product_name}
                    </h1>
                    {product.benefits.map((benefit, index) => (
                        <ul
                            key={index}
                            className="text-[13px] italic text-start text-black"
                        >
                            <li>{benefit}</li>
                        </ul>
                    ))}
                </div>
            </div>
        </div>
    );
}
